import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from "@mui/material";

export interface PropsICBDialogTitle {
    onClose: () => any;
    title: string,
}

// Styling and actions for dialog title
export default function ICBDialogTitle(props: PropsICBDialogTitle) {
    return (
        <DialogTitle fontWeight={'bold'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {props.title}
            <IconButton onClick={props.onClose} edge="end" aria-label="close">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}