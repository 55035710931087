import { Fragment, useContext } from "react";
import { ExerciseSelected, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Typography, useTheme } from "@mui/material";
import ExerciseCardSelected from "./ExerciseCardSelected";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";

interface PropsPracticeProgramSelectedExercises {
  displayOnly: boolean,
}

export default function PracticeProgramSelectedExercises(props: PropsPracticeProgramSelectedExercises) {
  const { state, } = useContext(Context) as TypeContext;
  const theme = useTheme();
  log.debug('PracticeProgramSelectedExercises');
  const { t } = useTranslation();

  // const refButtomOfComponent = useRef<HTMLElement | null>(null); // Add a reference so we can scrool to the top

  // // bring last selected exercise into view
  // useEffect(() => {
  //   if (refButtomOfComponent.current)
  //     refButtomOfComponent.current.scrollIntoView()
  // }, [])

  let startDateTime = new Date(state.curPractice.date)
  let endDateTime = new Date(state.curPractice.date)

  return (
    <Fragment>
      {state.curPractice.selectedExercises.length > 0 && state.exercisesRetrivedInitially ? (
        // Use when we have retrieved data
        <Fragment>
          {state.curPractice.selectedExercises
            // .filter(x => state.allExercises.find(y => y.attributes.drupal_internal__nid === x.drupal_internal__nid)) // don't show exercises not read yet!
            .map((exerciseSelected: ExerciseSelected, index: number) => {
              startDateTime = new Date(endDateTime);
              endDateTime.setTime(startDateTime.getTime() / 1 + 60 * 1000 * (exerciseSelected.durationMinutes || 0));
              return (
                <Box
                  // ref={index === state.curPractice.selectedExercises.length - 1 ? refButtomOfComponent : null} // keep ref to buttom of view!
                  key={index}
                  margin={1}
                >
                  <ExerciseCardSelected
                    // exerciseSelected={exerciseSelected}
                    /*
                    if the exercise for the exerciseSelected we are going to show has not been set by the recuduer in
                    action setPractice because the exercise was not read at that point in time then we try to see if
                    the exercise has been read now. Code below is only executed if exercise has been read due to the filter
                    live above and what we do below is find the exercise if the exercise was not found by the reducer in action
                    setPracticde
                    */
                    exerciseSelected={{
                      ...exerciseSelected,
                      exercise: exerciseSelected.exercise.id === ''
                        // set the exercise because the reducer was not able to
                        ? state.allExercises.find(x => x.attributes.drupal_internal__nid === exerciseSelected.drupal_internal__nid)!
                        // use the exercise that the reducer set
                        : exerciseSelected.exercise
                    }}
                    startAndStopTime={`${startDateTime.getHours().toString().padStart(2, '0')}:${startDateTime.getMinutes().toString().padStart(2, '0')}-${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}`}
                    index={index}
                    displayOnly={props.displayOnly}
                    upArrow={state.curPractice.selectedExercises.length > 1 && index > 0}
                    dnArrow={state.curPractice.selectedExercises.length > 1 && index < state.curPractice.selectedExercises.length - 1}
                  />
                </Box>
              );
            })}
          {
            // Allow for space below last exercise so there is room for pop up keyboard
            state.nativeApp && <Box height={250}></Box>
          }
        </Fragment>
      ) :
        (
          // Use when we have NOT retrieved data
          <Fragment>

            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', textAlign: 'center' }}>
              <Typography sx={{ paddingTop: 0, color: theme.palette.text.secondary, fontSize: 13 }}>
                {state.exercisesRetrivedInitially ? t('PracticeProgramSelectedExercises03') : t('PracticeProgramSelectedExercises01')}
              </Typography>
            </Box>

          </Fragment>
        )
      }
    </Fragment>
  );
}
