import { IconButton } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { ActionSetUserData, DrupalEntity, JSONAPITypeId, TypeContext } from '../misc/Types';
import { Fragment, useContext, useEffect, useState } from 'react';
import { Context } from '../App';
import { getDD, getRelationshipsDataElementFor } from '../misc/Functions';
import { BACKEND } from '../misc/Constants';

interface PropsFavorite {
    node: DrupalEntity,
    field: string,
}

export default function Favorite(props: PropsFavorite) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const [disabled, setDisabled] = useState(false)
    // Favorite has been flopped. Reenable favorites button
    useEffect(() => {
        setDisabled(false)
    }, [state.user.data])

    //  only give option to tag node as favorite if node not created by current suer
    if (state.user.data.id === props.node.relationships.uid.data.id)
        return (<Fragment></Fragment>)
    else
        return (
            <IconButton
                aria-label='flopFavorite'
                size='small'
                disabled={disabled}
                onClick={() => {
                    setDisabled(true)
                    getDD(state, dispatch, `${BACKEND}/icb-user/entity_list_in_out/${props.node.attributes.drupal_internal__nid}/${props.field}`)
                        .then(() => {
                            const field_favorite: JSONAPITypeId[] = state.user.data.relationships[props.field].data.find((x: JSONAPITypeId) => x.id === props.node.id)
                                ? state.user.data.relationships[props.field].data.filter((x: JSONAPITypeId) => x.id !== props.node.id)
                                : state.user.data.relationships[props.field].data.concat([getRelationshipsDataElementFor(props.node)])
                            const user: DrupalEntity = {
                                ...state.user.data,
                                relationships: {
                                    ...state.user.data.relationships,
                                    [props.field]: {
                                        data: field_favorite
                                    }
                                }
                            }
                            const action: ActionSetUserData = { type: 'setUserData', userData: user }
                            dispatch(action)
                        })
                }}
            >
                {state.user.data.relationships[props.field]?.data.find((x: JSONAPITypeId) => x.id === props.node.id)
                    ? <FavoriteIcon style={{ color: 'red' }} />
                    : <FavoriteBorderIcon />}
            </IconButton>
        )
}