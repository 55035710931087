import { lighten, Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import LanguageICB from "./LanguageICB";
import { getImageURL, landingPage } from "../misc/Functions";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";

// Simple page to utilize PWA facilities on Android
export default function InstallAndroid() {

    // const navigate = useNavigate();
    const { t } = useTranslation();
    const { state } = useContext(Context) as TypeContext;

    return (
        <Box sx={{ backgroundColor: '#0c1424' }}>

            {/* APP BAR */}
            <Box sx={{ paddingTop: { xs: 1, md: 2 }, maxWidth: '1250px', margin: 'auto' }}>
                <Box sx={{ width: '95%', margin: 'auto', display: 'flex', justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#0c1424', height: { xs: '50px', md: '57px' }, borderRadius: 4, border: '1px solid #2c3444' }}>
                    <img width={35} height={'auto'} onClick={() => {
                        if (!state.nativeApp)
                            window.location.href = landingPage()
                    }} src={getImageURL('logoICBWhite.webp')} style={{ borderRadius: "50%", marginLeft: 10, cursor: 'pointer' }} />

                    <Box sx={{ display: 'flex', gap: 1, marginRight: { xs: 1, md: 2 }, alignItems: 'center' }}>
                        <LanguageICB color={'white'} variant="contained" />
                    </Box>
                </Box>
            </Box>

            {/* IF THE INSTALL BUTTON SHOW, CLICK DOWNLOAD */}
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 4 }}>
                <Typography sx={{
                    fontSize: { xs: '20px', md: '30px' },
                    fontWeight: 600,
                    color: 'transparent',
                    background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)',
                    backgroundSize: '200% 200%',
                    WebkitBackgroundClip: 'text',
                    lineHeight: 1.1,
                    marginTop: '15px',
                }}>
                    {t('InstallAndroid00')}
                </Typography>
                <Typography sx={{
                    marginTop: '20px',
                    fontSize: '18px',
                    color: '#848dac',
                }}>
                    {t('InstallAndroid01')}
                </Typography>

                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid00.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto', border: '2px solid #00398F', borderRadius: 8 }} />
                </Box>
            </Box>

            {/* DIVIDER */}
            <Box sx={{
                height: '1px',
                width: '90%',
                background: `linear-gradient(to right, #000000, ${lighten('#0c1424', 0.3)}, #000000)`,
                margin: 'auto',
                marginTop: 5,
            }} />

            {/* IF THE INSTALL DOES NOT SHOW, FOLLOW THE PROCESS */}
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography sx={{ marginTop: 3, textTransform: 'none', fontSize: { xs: '15 px', sm: '18px' }, fontWeight: 'bold', color: 'transparent', background: 'linear-gradient(90deg, #99c2e0,#b3d1ec,#cce0f7,#b3d1ec,#99c2e0)', backgroundSize: '200% 200%', WebkitBackgroundClip: 'text', }}>
                    {t('InstallAndroid02')}
                </Typography>
                <Typography sx={{ marginTop: 1, fontSize: '14px', color: '#DD6F20' }}>
                    {t('InstallAndroid03')}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid01.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto', border: '2px solid lightgrey', borderRadius: 8 }} />
                </Box>
                <Typography sx={{ marginTop: 4, fontSize: '14px', color: '#DD6F20' }}>
                    {t('InstallAndroid04')}
                </Typography>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 2, paddingBottom: 20 }}>
                    <img src="/images/pwa/screenshots/InstallAndroid02.png.webp" alt="Descripción de la imagen 1" style={{ width: '95%', maxWidth: '350px', height: 'auto' }} />
                </Box>
            </Box>
        </Box >
    );
}
