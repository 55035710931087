import { useLocation } from 'react-router-dom';

export default function PDFViewer() {

    const stateUseLocation = useLocation();

    return (
        <div style={{ position: 'absolute', height: '100%', width: '100%', margin: 0, padding: 0 }}>
            <iframe src={stateUseLocation.state} style={{ position: 'absolute', height: '100%', width: '100%' }} />
        </div>
    );

}
