import { CardMedia, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { TypeContext } from "../misc/Types";
import { useContext } from "react";
import { Context } from "../App";
import CloseIcon from '@mui/icons-material/Close';
import log from "../misc/Logger";
import { exerciseVideo } from "../misc/Functions";

export interface PropsDialogShowVideo {
    open: boolean,
    onClose: () => void,
    fileIDVideo: string,
    concept: string,
}

// Show video specified in property fileIDVideo
export function DialogShowVideo(props: PropsDialogShowVideo) {
    const { state } = useContext(Context) as TypeContext;
    // log.info('DialogShowVideo - ' + props.fileIDVideo + ', - ' + exerciseVideo(state, props.fileIDVideo) + ', ' + state.allFiles.find(x => x.id === props.fileIDVideo));
    log.debug('DialogShowVideo')

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            //maxWidth='xs'
            PaperProps={{
                style: {
                    borderRadius: 8,
                },
            }}
        >
            <DialogTitle id="alert-dialog-description" fontWeight={'bold'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {props.concept}
                <IconButton onClick={props.onClose} edge="end" aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ padding: '8px 24px', marginBottom: 2 }}> {/* Adjust padding as needed */}
                <CardMedia
                    component='video'
                    src={exerciseVideo(state, props.fileIDVideo)}
                    preload="none"
                    //loop
                    autoPlay
                    controls
                    controlsList="nodownload"
                    sx={{ borderRadius: 1 }}
                />
            </DialogContent>
        </Dialog >
    )
}