import { Fragment, useState } from 'react';
import { Dialog, DialogContent, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export interface PropsHowToCheckAttendance {
    show: boolean,
    locale: string,
}

export default function HowToCheckAttendance(props: PropsHowToCheckAttendance) {
    const [open, setOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const theme = useTheme();
    const { t } = useTranslation();

    const images = [
        { image: `/images/nextTour/attendanceList/${props.locale}_DialogHowToCheckAttendance1.webp`, description: t('HowToCheckAttendance00') },
        { image: `/images/nextTour/attendanceList/${props.locale}_DialogHowToCheckAttendance2.webp`, description: t('HowToCheckAttendance01') },
        { image: `/images/nextTour/attendanceList/${props.locale}_DialogHowToCheckAttendance3.webp`, description: t('HowToCheckAttendance02') },
        { image: `/images/nextTour/attendanceList/${props.locale}_DialogHowToCheckAttendance4.webp`, description: t('HowToCheckAttendance03') },
        { image: `/images/nextTour/attendanceList/${props.locale}_DialogHowToCheckAttendance5.webp`, description: t('HowToCheckAttendance04') }
    ];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentImageIndex(0);
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handleBack = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    if (props.show)
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center', paddingBottom: 10, paddingTop:10 }}>
                    <Button variant="outlined" color="primary" sx={{ textTransform: 'none', borderRadius: 30 }} onClick={handleClickOpen}>
                        {t('HowToCheckAttendance05')}
                    </Button>
                </div>
                <Dialog open={open} onClose={handleClose}
                    PaperProps={{
                        style: {
                            borderRadius: 16,
                            width: '90%',
                            maxWidth: '450px',
                        },
                    }}
                >
                    <DialogContent>
                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: { xs: 14, sm: 17 },
                                textAlign: 'center',
                                fontWeight: 'bold'
                            }}
                            marginBottom={1}
                        >
                            {images[currentImageIndex].description}
                        </Typography>
                        <img
                            src={images[currentImageIndex].image}
                            alt={images[currentImageIndex].description}
                            style={{ width: '100%', height: 'auto', borderRadius: 6, border: `1px solid ${theme.palette.divider}` }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', gap: '10px' }}>
                            <Button variant="outlined" color="primary" sx={{ textTransform: 'none' }} onClick={handleBack} disabled={currentImageIndex === 0}>{t('HowToCheckAttendance06')}</Button>
                            <Button variant="contained" color="primary" sx={{ textTransform: 'none' }} onClick={handleNext} disabled={currentImageIndex === images.length - 1}>{t('HowToCheckAttendance07')}</Button>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        );
    else
        return (
            <Fragment></Fragment>
        )
};
