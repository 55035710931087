import { PaletteMode } from "@mui/material";
import { amber } from "@mui/material/colors";

const theme = {
  palette: {
    primary: amber,
  },
};

export const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
        // LIGHT MODE
        primary: {
          main: "#00398F", //blue ICB
        },
        secondary: {
          main: "#DD6F20" //orange ICB
        },
        divider: "rgba(0, 0, 0, 0.12)",
        text: {
          primary: "rgba(0, 0, 0, 0.87)",
          secondary: "rgba(0, 0, 0, 0.6)",
          disabled: "rgba(0, 0, 0, 0.38)",
        },
        action: {
          active: "rgba(0, 0, 0, 0.54)",
          hover: "rgba(0, 0, 0, 0.04)",
          selected: "rgba(0, 0, 0, 0.08)",
          disabled: "rgba(255, 255, 255, 0.8)", // BACKGROUND SMALLSCREENICBAPPBAR
          disabledBackground: "#d3e8fe", //BLUE FOR WEEKLY PRACTICES & CRUDLISTS
        },

        background: {
          default: "#fff",
          paper: "#fff",
        },
      }
      : {
        // DARK MODE
        primary: {
          main: "#fff"
        },
        secondary: {
          main: "#fff"
        },
        terciary: {
          main: "#d47029" //TO BE DETERMINATED
        },
        quaternary: {
          main: "#7bb5ee" //T0 BE DETERMINATED
        },
        divider: "rgba(255, 255, 255, 0.12)",
        text: {
          primary: "#fff",
          secondary: "rgba(255, 255, 255, 0.7)",
          disabled: "rgba(255, 255, 255, 0.5)",
        },
        action: {
          active: "rgba(255, 255, 255, 0.54)",
          hover: "rgba(255, 255, 255, 0.04)",
          selected: "rgba(255, 255, 255, 0.08)",
          disabled: "rgba(0, 0, 0, 0.9)", // BACKGROUND SMALLSCREENICBAPPBAR
          disabledBackground: "rgba(255, 255, 255, 0.12)",
        },

        background: {
          default: "#121212",
          paper: "#121212",
        },
      }),
  },
});

export default theme;