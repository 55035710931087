// import { DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
// import { useEffect, useState } from 'react';

import { Fragment } from "react/jsx-runtime";

export default function Testdetherdugamle() {
    // const { data } = useDemoData({
    //     dataSet: 'Commodity',
    //     rowLength: 10,
    //     maxColumns: 6,
    // });

    // const [rowSelectionModel, setRowSelectionModel] =
    //     useState<GridRowSelectionModel>([]);

    // useEffect(() => {
    //     if (data.rows.length > 0) {
    //         console.log('test')
    //         setRowSelectionModel([data.rows[1].id, data.rows[3].id])
    //     }
    // }, [data.rows])


    // var columns = JSON.parse(JSON.stringify(data.columns))
    // columns[0].hide = false
    // const dataTPS = { ...data, columns: columns, initialState: {} }

    // return (
    //     <div style={{ height: 400, width: '100%' }}>
    //         <DataGrid
    //             checkboxSelection
    //             onRowSelectionModelChange={(newRowSelectionModel) => {
    //                 setRowSelectionModel(newRowSelectionModel);
    //             }}
    //             rowSelectionModel={rowSelectionModel}
    //             {...dataTPS}
    //         />
    //     </div>
    // );
    return (
        <Fragment></Fragment>
    )
}
