import { Box, Switch, SwitchProps, styled } from "@mui/material";
import { useThemeContext } from "../theme/ThemeContextProvider";
import { useContext } from "react";
import { CRUD, DrupalEntity, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { nodeCRUD } from "../misc/Functions";

const NightModeToggle = () => {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { mode, toggleColorMode } = useThemeContext();

    const IOSSwitch = styled((props: SwitchProps) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    return (
        <Box display="flex" alignItems="center" gap="8px">
            <IOSSwitch
                checked={mode === "dark"}
                onChange={() => {
                    toggleColorMode()
                    const userData: DrupalEntity = { //JSON.parse(JSON.stringify(state.user.data));
                        id: state.user.data.id,
                        type: 'user--user',
                        attributes: {
                            field_theme_dark_mode: mode === 'light'
                        }
                    }
                    nodeCRUD(state, dispatch, CRUD.Update, userData)
                }}
                color="default"
            />
        </Box>
    );
};

export default NightModeToggle;