import { useContext } from "react";
import { CircularProgress, Backdrop } from "@mui/material";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";
import { getImageURL } from "../misc/Functions";
import log from "../misc/Logger";

export default function ICBBackdrop() {
  const { state } = useContext(Context) as TypeContext;
  log.debug('ICBBackdrop');

  return (
    <Backdrop
      sx={{
        color: '#d47029',
        zIndex: (theme) => theme.zIndex.drawer + 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
      open={state.backdrop > 0}
    >
      <div style={{ position: 'relative' }}>
        <CircularProgress
          style={{ color: '#15468f' }} // Set the spinner color to #15468f
          size={100}
        />
        <img
          src={getImageURL('logoICBWhite.webp')}
          alt="Logo ICB Blue"
          style={{
            position: 'absolute',
            top: '47%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            height: '60%', // Adjust size as needed
            width: '60%',  // Adjust size as needed
            borderRadius: '50%' // To make the image circular
          }}
        />
      </div>
    </Backdrop>
  );
}