import React, { Fragment, useContext } from 'react';
import { Grid, TextField, Button, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Context } from '../App';
import { TypeContext, UserType } from '../misc/Types';
import { getActionSetConfirm, icbControllerGenerel02, rereadUser } from '../misc/Functions';

export default function JoinClub() {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context) as TypeContext;
    const [field_my_club_admin_hash, setField_my_club_admin_hash] = React.useState('');
    const theme = useTheme();

    async function _joinClub() {
        if (!field_my_club_admin_hash) {
            dispatch(getActionSetConfirm(t('SetSubscription01')))
            return
        }
        let resp = await icbControllerGenerel02(state, {
            opr: "set_subscription",
            field_user_type: UserType.club,
            field_my_club_admin_hash: field_my_club_admin_hash,
            // return messages to the user
            invalid_club_admin_hash: t('AlertMsg14'),
        })
        if (!resp.ok) {
            dispatch(getActionSetConfirm(resp.error))
            return
        }
        dispatch(getActionSetConfirm(t('AlertMsg09')))
        rereadUser(state, dispatch, state.user.data.id!)
    }

    return (

        <Fragment>
            <Typography
                sx={{
                    paddingLeft: 0.5,
                    paddingTop: 1,
                    fontSize: state.portrait ? '12px' : '14px',
                    color: theme.palette.text.secondary
                    //color: theme.palette.primary.main
                }}
            >
                {t('Home13')}
            </Typography>

            <Grid container sx={{ margin: 'auto', marginTop: 0.5 }}>
                <Grid item xs={9} sx={{ paddingRight: 1 }}>
                    <TextField
                        fullWidth
                        size='small'
                        value={field_my_club_admin_hash}
                        label={t('JoinClub00')}
                        //label={t('Home12')}
                        variant="outlined"
                        // helperText={t('Home13')} 
                        sx={{ width: '100%' }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setField_my_club_admin_hash(event.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={3} container justifyContent="center" alignItems="center">
                    <Button
                        fullWidth
                        onClick={async () => {
                            // if (!field_my_club_admin_hash) {
                            //     dispatch(getActionSetConfirm(t('SetSubscription01')))
                            //     return
                            // }
                            // let resp = await icbControllerGenerel02(state, {
                            //     opr: "set_subscription",
                            //     field_user_type: UserType.club,
                            //     field_my_club_admin_hash: field_my_club_admin_hash,
                            //     // return messages to the user
                            //     invalid_club_admin_hash: t('AlertMsg14'),
                            // })
                            // if (!resp.ok) {
                            //     dispatch(getActionSetConfirm(resp.error))
                            //     return
                            // }
                            // dispatch(getActionSetConfirm(t('AlertMsg09')))
                            // rereadUser(state, dispatch, state.user.data.id!)

                            // If paying user then confirm that change to club member
                            if ([UserType.pro, UserType.clubadmin].includes(state.user.data.attributes.field_user_type)) {
                                dispatch(getActionSetConfirm(
                                    state.user.data.attributes.field_user_type === UserType.pro ? t('SetSubscription13') : t('SetSubscription20'), 'OK', () => {
                                    _joinClub()
                                }));
                    
                            } else {
                                _joinClub()
                            }
                        }}
                        variant='outlined'
                        sx={{
                            height: '40px',
                            textTransform: 'none',
                        }}
                    >
                        {t('Home14')}
                    </Button>
                </Grid>
            </Grid>
        </Fragment>
    );
};