import { useContext, useEffect, useRef, useState, } from 'react';
import Exercises from "./Exercises";
import PracticeProgram from './PracticeProgram';
import { OperationMode, TypeContext } from '../misc/Types';
import { Context } from '../App';
import Grid2 from '@mui/material/Unstable_Grid2';
import log from "../misc/Logger";
import CreatePlaybook from './CreatePlaybook';
import { Box } from '@mui/material';

export default function FrontPage() {
    const { state } = useContext(Context) as TypeContext;
    log.debug('FrontPage');

    // width of two columns layout
    const [col1, setCol1] = useState(8)
    const [col2, setCol2] = useState(4)

    const boxRef = useRef<HTMLElement | null>(null); // Add a reference so we can scrool to the top

    // change width of two columns layout
    useEffect(() => {
        if (state.portrait) {
            if (state.showPracticeProgram) {
                setCol1(0)
                setCol2(12)
            } else {
                setCol1(12)
                setCol2(0)
            }
        } else {
            if (state.showPracticeProgram) {
                setCol1(8)
                setCol2(4)
            } else {
                setCol1(12)
                setCol2(0)
            }
        }
    }, [state.portrait, state.showPracticeProgram, state.portrait])

    // scroll to the top when a new group of exercises or plays in selected in the appbar
    useEffect(() => {
        if (boxRef.current) {
            boxRef.current.scrollTop = 0; // Scroll to the top
        }
    }, [state.selectedGroup, state.selectedExercisePool])

    return (
        // add box around content for independant scrolling in columns
        <Grid2 container>
            <Grid2 xs={col1} style={{ transition: !state.portrait ? 'width 0.3s ease-in-out' : 'none' }}>
                <Box sx={{ overflowY: 'scroll', height: '100vh' }} ref={boxRef}>
                    <Exercises />
                </Box>
            </Grid2>
            <Grid2 xs={col2} style={{ transition: !state.portrait ? 'width 0.3s ease-in-out' : 'none' }}>
                <Box sx={{ overflowY: 'scroll', height: '100vh' }}>
                    {state.operationMode === OperationMode.exercise ? <PracticeProgram /> : <CreatePlaybook />}
                </Box>
            </Grid2>
        </Grid2>
    )
}
