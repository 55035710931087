import { Fragment, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getActionSetConfirm, nodeCRUD } from "../misc/Functions";
import { CRUD, DataGridActions, DataGridUpdate, DrupalEntity, JSONAPITypeId, TypeContext, UserType } from "../misc/Types";
import { useNavigate } from "react-router-dom";
import CRUDList from "./CRUDList";
import { BACKEND, DRUPALENTITYINIT, } from "../misc/Constants";
import mime from 'mime';
import { Box, Divider, Typography, useTheme } from "@mui/material";

export default function ClubDocuments() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('ClubDocuments');
    const navigate = useNavigate();
    const theme = useTheme();

    const [clubAdmin, setClubAdmin] = useState<DrupalEntity>(DRUPALENTITYINIT)

    // Find my clubadmin or myself if I am a clubadmin
    useEffect(() => {
        if (state.user.data.attributes.field_user_type === UserType.club) {
            setClubAdmin(state.allUsers.find(x => x.id === state.user.data.relationships.field_my_club_admin.data.id)!)
        } else if (state.user.data.attributes.field_user_type === UserType.clubadmin) {
            setClubAdmin(state.user.data)
        }
    }, [state.user.data])

    // Only the clubadmin should be able to work on the list of club documents. The club user should only be able to view the document
    const actions = [DataGridActions.viewClubDocument]
        .concat(state.user.data.attributes.field_user_type === UserType.clubadmin
            ? [DataGridActions.down, DataGridActions.up, DataGridActions.delete, DataGridActions.edit, DataGridActions.save, DataGridActions.cancel]
            : [])

    const mimeTypeClubDocuments = ['application/pdf', 'text/plain']

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const columns = [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'description', headerName: t('CRUDList09'), flex: 1, editable: state.user.data.attributes.field_user_type === UserType.clubadmin },
    ]

    // Upload club document
    // TO DO use Drupal File.Validator Serivce. See https://www.drupal.org/node/3363700
    async function handleClubDocumentFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        // event.preventDefault();
        log.info('handleClubDocumentFileChange, upload type: ' + event.target.files![0].type + '. Name: ' + event.target.files![0].name + '. Size: ' + event.target.files![0].size);
        if (event.target.files && event.target.files.length > 0) {
            const mimeType = mime.getType(event.target.files[0].name) || ''
            // Check MIME type and size
            if (mimeTypeClubDocuments.includes(mimeType) && event.target.files[0].size < 2000000) {
                // add new file to users list of "field_club_documents" and return new list of "field_club_documents"
                // const resp = await addFileToNode(state, dispatch, event.target.files[0], state.user.data, "field_club_documents")
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, state.user.data, event.target.files[0], "field_club_documents")
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp))
                    return resp
                }

                // set initial description equal to file name
                const field_club_documents_new_element: JSONAPITypeId = {
                    id: resp.data[resp.data.length - 1].id,
                    type: 'file--file',
                    meta: { description: event.target.files[0].name }
                }

                // state.user.data.relationships.field_club_documents still holds the 'old' list of club documents before file above was added to list
                const userData: DrupalEntity = {
                    type: 'user--user',
                    id: state.user.data.id,
                    relationships: {
                        field_club_documents: {
                            data: state.user.data.relationships.field_club_documents.data.concat(field_club_documents_new_element)
                        }
                    }
                }
                await nodeCRUD(state, dispatch, CRUD.Update, userData)
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg36')));
            }
        }
    };

    // processRowUpdate is called after rows are moved up/down, values in row updated, row is deleted
    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                // If relevant then find file to delete
                if (dataGridUpdate.delete) {
                    const userData: DrupalEntity = {
                        id: state.user.data.id,
                        type: 'user--user',
                        relationships: {
                            field_club_documents: state.user.data.relationships.field_club_documents.data.filter((x: JSONAPITypeId) => x.id !== dataGridUpdate.id)
                        }
                    }
                    // delete file and update user 
                    nodeCRUD(state, dispatch, CRUD.Delete, state.user.data.relationships.field_club_documents.data.find((x: JSONAPITypeId) => x.id === dataGridUpdate.id))
                    nodeCRUD(state, dispatch, CRUD.Update, userData)
                }

                // Check we have description
                if (dataGridUpdate.rows.find(x => x.description.trim() === '')) {
                    reject(new Error(t('ClubDocuments00')))
                    return
                }

                // Update file description
                const userData: DrupalEntity = {
                    type: 'user--user',
                    id: state.user.data.id,
                    relationships: {
                        field_club_documents: {
                            // data: state.user.data.relationships.field_club_documents.data.map((item: JSONAPITypeId) => item.id === dataGridUpdate.newRow!.id ? {type: 'file--file', id: item.id, meta: {description: dataGridUpdate.newRow!.description}} : item)
                            data: dataGridUpdate.rows.map(item => ({ type: 'file--file', id: item.id, meta: { description: item.description } }))
                        }
                    }
                }
                nodeCRUD(state, dispatch, CRUD.Update, userData)

                // Update user data and save updated user data
                resolve(dataGridUpdate.rows)
            })
    }

    return (
        <Fragment>

            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('ClubDocuments02')}
                </Typography>
                <Divider />
            </Box>

            <CRUDList
                rows={
                    clubAdmin.relationships.field_club_documents
                        ? clubAdmin.relationships.field_club_documents.data
                            .map((item: JSONAPITypeId) => ({
                                id: item.id,
                                description: item.meta!.description,
                                // if we delete club docuemnt then we shortly have a case where the user refers to a club document that has been deleted. Protect agaist that case
                                url: state.allFiles.find(x => x.id === item.id) ? state.allFiles.find(x => x.id === item.id)!.attributes.uri.value : '',
                            }))
                        : []

                    // clubAdmin.relationships.field_club_documents
                    //     ? clubAdmin.relationships.field_club_documents.data
                    //         .map((item: JSONAPITypeId) => ({
                    //             id: item.id,
                    //             description: item.meta!.description,
                    //             // if we delete club docuemnt then we shortly have a case where the user refers to a club document that has been deleted. Protect agaist that case
                    //             url: state.allFiles.find(x => x.id === item.id) ? state.allFiles.find(x => x.id === item.id)!.attributes.uri.value : '',
                    //         }))
                    //     : []

                    // state.user.data.relationships.field_club_documents.data
                    //     .map((item: JSONAPITypeId) => ({
                    //         id: item.id,
                    //         description: item.meta!.description,
                    //         // if we delete club docuemnt then we shortly have a case where the user refers to a club document that has been deleted. Protect agaist that case
                    //         url: state.allFiles.find(x => x.id === item.id) ? state.allFiles.find(x => x.id === item.id)!.attributes.uri.value : '',
                    //     })).concat(
                    //         state.allUsers.find(x => x.id === state.user.data.attributes.field_my_club_admin).relationships.field_club_documents.data
                    //             .map((item: JSONAPITypeId) => ({
                    //                 id: item.id,
                    //                 description: item.meta!.description,
                    //                 // if we delete club docuemnt then we shortly have a case where the user refers to a club document that has been deleted. Protect agaist that case
                    //                 url: state.allFiles.find(x => x.id === item.id) ? state.allFiles.find(x => x.id === item.id)!.attributes.uri.value : '',
                    //             }))
                    //     )



                }
                columns={columns}
                actions={actions}
                processRowViewClubDocument={(id) => {
                    const url = `${BACKEND}${state.allFiles.find(x => x.id === id)!.attributes.uri.url}`
                    navigate('/practicereport', { state: url })
                }
                }
                processRowUpdate={processRowUpdate}
                labelAddRow={state.user.data.attributes.field_user_type === UserType.clubadmin ? t('CRUDList08') : undefined}
                gridColumnVisibilityModel={{ id: false }}
                columnFocusOnNew="description"
                deleteWarning={t('ClubDocuments03')}
                onClickAddRow={handleClubDocumentFileChange}
            />
        </Fragment>
    )
}