import { createTheme, PaletteMode } from "@mui/material";
import { useMemo, useState } from "react";
import { getDesignTokens } from "./Theme";

export const useColorTheme = () => {
  // const { state, dispatch } = useContext(Context) as TypeContext;
  const [mode, setMode] = useState<PaletteMode>("light");

  const toggleColorMode = () =>
    setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));

  const modifiedTheme = useMemo(
    () => createTheme(getDesignTokens(mode)),
    [mode]
  );

  return {
    theme: modifiedTheme,
    mode,
    toggleColorMode,
  };
};