import { Button, Typography, Grid, darken } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import { useTranslation } from 'react-i18next';
import { lighten } from '@mui/system';


interface ActionButtonsProps {
    selectedPlayerId: number | null;
    createLine: (type: 'straight' | 'zigzag' | 'dashed' | 'bar') => void;
    handleCreateNewFrame: () => void;
    setIsModalOpen: (isOpen: boolean) => void;
    handleDeleteLine: () => void;
    handleToggleBall: () => void;
    handleSavePlay: () => void;
    // handleSendPlay: () => void;
}

export default function ActionButtons({
    // selectedPlayerId,
    createLine,
    //handleCreateNewFrame,
    //setIsModalOpen,
    handleDeleteLine,
    handleToggleBall,
    //handleSavePlay,
    // handleSendPlay,
}: ActionButtonsProps) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();

    return (
        <>
            {!state.portrait ? (
                <div style={{ marginBottom: '10px' }}>
                    <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '10px', textAlign: 'center', color: '#00398f' }}>
                        {t('ActionButtons00')}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    '&:hover': {
                                        border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    },
                                }}
                                onClick={() => createLine('straight')}
                            >
                                {t('ActionButtons01')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    '&:hover': {
                                        border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    },
                                }}
                                onClick={() => createLine('zigzag')}
                            >
                                {t('ActionButtons02')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    '&:hover': {
                                        border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    },
                                }}
                                onClick={() => createLine('dashed')}
                            >
                                {t('ActionButtons03')}
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                fullWidth
                                sx={{
                                    textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    '&:hover': {
                                        border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                    },
                                }}
                                onClick={() => createLine('bar')}
                            >
                                {t('ActionButtons04')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                fullWidth
                                sx={{
                                    textTransform: 'none', borderRadius: '5px', color: 'white', backgroundColor: '#DD620f',
                                    '&:hover': {
                                        backgroundColor: darken('#DD620f', 0.1),
                                    },
                                }}
                                onClick={handleToggleBall}
                            >
                                {t('ActionButtons05')}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="error"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={handleDeleteLine}
                            // disabled={selectedPlayerId === null}
                            >
                                {t('ActionButtons06')}
                            </Button>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                sx={{ textTransform: 'none', borderRadius: '5px' }}
                                onClick={handleSavePlay}
                            >
                                Save Play
                            </Button>
                        </Grid> */}
                    </Grid>
                </div>
            ) :

                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                                textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                '&:hover': {
                                    border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                },
                            }}
                            onClick={() => createLine('straight')}
                        >
                            {t('ActionButtons01')}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                                textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                '&:hover': {
                                    border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                },
                            }}
                            onClick={() => createLine('zigzag')}
                        >
                            {t('ActionButtons02')}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                                textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                '&:hover': {
                                    border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                },
                            }}
                            onClick={() => createLine('dashed')}
                        >
                            {t('ActionButtons03')}
                        </Button>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            size="small"
                            fullWidth
                            sx={{
                                textTransform: 'none', borderRadius: '5px', color: '#00398f', border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                '&:hover': {
                                    border: `0.5px solid ${lighten('#00398f', 0.5)}`,
                                },
                            }}
                            onClick={() => createLine('bar')}
                        >
                            {t('ActionButtons04')}
                        </Button>
                    </Grid>
                    <Grid item xs={8}>
                        <Button
                            variant="contained"
                            size="small"
                            fullWidth
                            sx={{
                                textTransform: 'none', borderRadius: '5px', color: 'white', backgroundColor: '#DD620f',
                                '&:hover': {
                                    backgroundColor: darken('#DD620f', 0.1),
                                },
                            }}
                            onClick={handleToggleBall}
                        >
                            <SportsBasketballRoundedIcon style={{ fontSize: '16px' }} />
                            {t('ActionButtons05')}
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            fullWidth
                            sx={{ textTransform: 'none', borderRadius: '5px' }}
                            onClick={handleDeleteLine}
                        // disabled={selectedPlayerId === null}
                        >
                            {t('ActionButtons06')}
                        </Button>
                    </Grid>
                </Grid>
            }
        </>
    );
}