import { Fragment, useContext, useState } from "react";
import { Context } from "../App";
import { TypeContext } from "../misc/Types";
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import { Button, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { formatLanguage } from "../misc/Functions";
import { useTranslation } from "react-i18next";

interface LanguageICBProps {
    style?: React.CSSProperties;
    color?: string;
    variant?: 'text' | 'outlined' | 'contained';
}

export default function LanguageICB({ style, color = 'grey', variant = 'text' }: LanguageICBProps) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { i18n } = useTranslation();

    const [anchorElLanguageMenu, setAnchorElLanguageMenu] = useState<null | HTMLElement>(null);
    // const [_cookies, setCookie] = useCookies(['icoachbasketball']);

    // Define un tipo para las claves de los locales soportados
    type LocaleKey = 'enUS' | 'esES' | 'caES';

    // Usa el tipo LocaleKey para las claves del objeto
    const localeToLanguageName: Record<LocaleKey, string> = {
        enUS: 'English',
        esES: 'Español',
        caES: 'Català',
    };

    return (
        <Fragment>

            <Button
                onClick={(event) => setAnchorElLanguageMenu(event.currentTarget)}
                variant={variant}
                sx={{ textTransform: 'none', ...style, color: color }}
            >
                <LanguageIcon fontSize="small" sx={{ color: color }} />
                {localeToLanguageName[state.user.locale as LocaleKey]}
            </Button>

            <Menu
                id="menu-appbar03"
                anchorEl={anchorElLanguageMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElLanguageMenu)}
                onClose={() => setAnchorElLanguageMenu(null)}
            >
                {/* {Object.keys(locales).map((item) => { */}
                {['enUS', 'esES', 'caES'].map((item) => {
                    return (
                        <MenuItem
                            key={item}
                            onClick={() => {
                                dispatch({ type: 'setLocale', locale: item })
                                i18n.changeLanguage(formatLanguage(item));
                                setAnchorElLanguageMenu(null);
                            }}>
                            {/* Use the mapping to display the language name */}
                            {localeToLanguageName[item as LocaleKey] || item}
                            {
                                item === state.user.locale ?
                                    <ListItemIcon>
                                        <CheckIcon />
                                    </ListItemIcon>
                                    : ''
                            }
                        </MenuItem>
                    )
                })}
            </Menu>
        </Fragment>
    )
}