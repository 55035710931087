import React, { Fragment, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';

interface SearchFieldProps {
  onSearch: (query: string) => void;
}

export default function SearchField({ onSearch }: SearchFieldProps) {
  const [query, setQuery] = useState('');
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
    onSearch(value);
  };

  const handleClear = () => {
    setQuery('');
    onSearch('');
  };

  return (
    <Fragment>
      <TextField
        label=""
        placeholder={t('SearchField00')}
        variant="outlined"
        value={query}
        onChange={handleChange}
        sx={{
          //backgroundColor: '#f4f4f4',
          borderRadius: '6px',
          width: '100%',
          maxWidth: '850px',
          '& .MuiOutlinedInput-root': {
            padding: '0 10px',
            height: '36px',
            borderRadius: '6px',
            border: `1px solid ${theme.palette.divider}`,
            '&.Mui-focused': {
              border: `2px solid ${theme.palette.divider}`,
            },
          },
          '& .MuiOutlinedInput-input': {
            padding: '8px 0',
            color: 'darkgrey',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .MuiInputBase-input::placeholder': {
            color: 'grey', // placeholder darker color
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <IconButton size="small" onClick={handleClear}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Fragment>
  );
}