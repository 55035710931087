import log from "loglevel";
import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getActionSetConfirm, getClub, icbControllerGenerel02} from "../misc/Functions";
import { TypeContext } from "../misc/Types";
import { Box, Button, Typography } from "@mui/material";
import { printf } from "fast-printf";

export default function SendClubMemberInviration() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    log.debug('SendClubMemberInviration()');
    const { t } = useTranslation();

    const [clubSHA, setClubSHA] = useState();

    useEffect(() => {
        if (!clubSHA) {
            icbControllerGenerel02(state, { "opr": "get_club_sha" })
                .then((resp) => {
                    if (!resp.ok) {
                        dispatch(getActionSetConfirm(resp.error))
                        return
                    }
                    setClubSHA(resp.club_sha);
                })
        }
    }, [state.user.data.id]) // dependency array needed if user refreshes/F5

    return (
        <Fragment>
            <Box
                textAlign={'center'}
                sx={{
                    // You can adjust the gradient to your preference
                    background: 'linear-gradient(to bottom, #f4fcfc, white)',
                }}
            >
                {
                    clubSHA &&
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={() => {
                            // Build the mail body. Turn '\n' from translation info new line
                            let mailBody = t('SendClubMemberInviration01')
                            mailBody = printf(mailBody, getClub(state), `${window.location.origin}/?q=clubinvite&club=${clubSHA}`, state.user.data.attributes.display_name)
                            mailBody = encodeURIComponent(mailBody);
                            mailBody.replace('\n', '%0D%0A')
                            mailBody += `%0D%0A${state.user.data.attributes.display_name}${encodeURIComponent(',')} ${getClub(state)}%0D%0A%0D%0A${clubSHA}`

                            const link = document.createElement("a");
                            link.href = `mailto:?body=${mailBody}&subject=${t('SendClubMemberInviration00')} "${state.user.data.attributes.field_club_name}"`;
                            link.target = "_blank";
                            link.click();
                        }}
                    >
                        <Typography variant="button" style={{ fontSize: '0.69rem' }}>
                            {t('SendClubMemberInviration03')} "{state.user.data.attributes.field_club_name}"
                        </Typography>
                    </Button>
                }
            </Box>
        </Fragment >
    )
}