import TagFaces from '@mui/icons-material/TagFaces';
import { useContext } from 'react';
import log from '../misc/Logger';
import { Context } from '../App';
import { Box, Typography } from '@mui/material';
import { CDN } from '../misc/Constants';
import { DrupalEntity, TypeContext } from '../misc/Types';

interface UserPictureAndNameProps {
    entity: DrupalEntity,
    tagFacesColor?: string;
    typographyColor?: string;
}

export default function UserPictureAndName(props: UserPictureAndNameProps) {
    const { state } = useContext(Context) as TypeContext;
    log.debug('UserPictureAndName()');

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {
                props.entity.relationships.user_picture.data && state.allFiles.length !== 0 ?
                    <img
                        src={`${CDN}/${state.allFiles.find(x => x.id === props.entity.relationships.user_picture.data.id)?.attributes.uri.url}.webp`}
                        height="24" // Ajusta esto según sea necesario
                        width="24" // Ajusta esto según sea necesario
                        style={{ borderRadius: "50%", height: '1.5em', width: '1.5em' }}
                        alt="User"
                    />
                    : <TagFaces style={{ color: props.tagFacesColor }}></TagFaces>
            }

            <Typography sx={{ fontSize: { xs: 10, sm: 12 }, marginLeft: '0.5em', color: props.typographyColor }}>
                {props.entity.attributes.field_first_name} {props.entity.attributes.field_last_name}
            </Typography>

            <Typography sx={{ fontSize: { xs: 10, sm: 12 }, marginLeft: '0.2em', color: props.typographyColor }}>
                {"- (" + props.entity.attributes.display_name + ")"}{state.debug ? ` (debug: ${props.entity.attributes.drupal_internal__uid})` : ''}
            </Typography>

        </Box>
    )
}