import { Button, Typography } from "@mui/material"
import { Fragment, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { getDD } from "../misc/Functions";
import { Context } from "../App";
import { DrupalEntity, TypeContext } from "../misc/Types";
import log from "../misc/Logger";
import { BACKEND, JSONAPIPATH } from "../misc/Constants";

export default function TermsAndConditions() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    log.debug('TermsAndConditions()');

    const [termsAndConditions, setTermsAndConditions] = useState<DrupalEntity>({ type: '' });

    // Get term and conditions from the backend
    useEffect(() => {
        // Use first terms node in case there are more!
        getDD(state, dispatch, `${BACKEND}/${JSONAPIPATH}/node/terms_and_conditions`)
            .then((json) => setTermsAndConditions(json.data[json.data.length - 1]))
    }, []);

    return (
        <Fragment>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Typography>
                {termsAndConditions.attributes ? termsAndConditions.attributes.title : ''}
            </Typography>
            <div className="content" dangerouslySetInnerHTML={{ __html: termsAndConditions.attributes ? termsAndConditions.attributes.body.processed : '' }} />
        </Fragment>
    )
}
