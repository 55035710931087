import { Box } from "@mui/material"
import Grid2 from "@mui/material/Unstable_Grid2"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { useContext } from "react"
import { Fragment } from "react/jsx-runtime"
import { Context } from "../App"
import { TypeContext } from "../misc/Types"
import { useTranslation } from "react-i18next"
import log from "loglevel"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs, { Dayjs } from 'dayjs';
import { toDrupalDate } from "../misc/Functions"

interface PropsDateFromDateTo {
    handleChange: (name: string, value: any) => any,
    date_from: Date,
    date_to: Date,
}

export default function DateFromDateTo(props: PropsDateFromDateTo) {
    const { state, } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DateFromDateTo');

    // Date or time has changed. Pass change on to onChange()
    const handleChangeDateTime = (name: string, value: Dayjs | null) => {
        if (value?.isValid()) {
            let dateString = toDrupalDate(value.toISOString());
            // Update local state
            props.handleChange(name, dateString)
        }
    };

    return (
        <Fragment>

            <Grid2 style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: 0 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, width: '100%', maxWidth: '450px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={state.user.locale.substring(0, 2)}>
                        <Grid2 xs={6}>
                            <DatePicker
                                key={'date_from'}
                                label={t('FormGetPracticeReport06')}
                                value={dayjs(new Date(props.date_from))}
                                onChange={(newValue) => { handleChangeDateTime('date_from', newValue) }}
                                sx={{
                                    width: '100%',
                                    '.MuiInputBase-root': {
                                        height: '40px', // Set the height for the input field
                                    },
                                    '.MuiInputBase-input': {
                                        color: 'grey', // Set the text color to grey
                                    },
                                }}
                            />
                        </Grid2>
                        <Grid2 xs={6} sx={{ paddingLeft: 1 }}>
                            <DatePicker
                                key={'date_to'}
                                label={t('FormGetPracticeReport07')}
                                // value={dayjs(new Date().setHours(23, 59, 59, 0))}
                                value={dayjs(new Date(props.date_to))}
                                onChange={(newValue) => { handleChangeDateTime('date_to', newValue) }}
                                sx={{
                                    width: '100%',
                                    '.MuiInputBase-root': {
                                        height: '40px', // Set the height for the input field
                                    },
                                    '.MuiInputBase-input': {
                                        color: 'grey', // Set the text color to grey
                                    },
                                }}
                            />
                        </Grid2>
                    </LocalizationProvider>
                </Box>
            </Grid2>
        </Fragment>
    )
}