import { useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import { TypeContext } from '../misc/Types';
import { Context } from '../App';
import MyContacts from './MyContacts';
import log from "../misc/Logger";
import { DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

interface PropsDialogMyContacts {
    open: boolean,
    onClose: (ok: boolean) => void, // call with true if dialog action is to be performed. Call with false if dialog action is cancelled
}

export default function DialogMyContacts(props: PropsDialogMyContacts) {
    const { state } = useContext(Context) as TypeContext;
    log.debug('DialogMyContacts');
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <Dialog
            open={props.open}
            PaperProps={{
                style: {
                    width: '100%',
                    maxWidth: '600px',
                    borderRadius: 6,
                    position: state.nativeApp ? 'absolute' : 'static', // Aplica posición absoluta solo si el TextField está enfocado y es una app nativa
                    top: state.nativeApp ? '5%' : undefined, // Aplica el top solo si el TextField está enfocado y es una app nativa
                },
            }}
        >

            <DialogTitle fontWeight={'bold'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {t('MyContacts04')}
                <IconButton onClick={() => props.onClose(false)} edge="end" aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Typography sx={{ paddingRight: 2, paddingLeft: 2, color: theme.palette.text.secondary, fontSize: { xs: 13, sm: 16 } }}>
                {t('MyContacts08')}
            </Typography>
            <MyContacts
                onClose={props.onClose}
            />

        </Dialog>
    );
}