import { Button, Checkbox, Dialog, DialogActions, DialogContent, Divider, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { ActionSetPractice, Attend, CRUD, TypeContext } from "../misc/Types";
import log from "../misc/Logger";
import DialogMyPlayers from "./DialogMyPlayers";
import { getActionSetConfirm, nodeCRUD, savePractice, } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useTheme } from "@mui/material/styles";
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ICBDialogTitle from "./ICBDialogTitle";

interface PropsDialogAttendanceListForPractice {
    open: boolean,
    onClose: () => void,
}

export default function DialogAttendanceListForPractice(props: PropsDialogAttendanceListForPractice) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();
    log.debug('DialogAttendanceListForPractice');

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

    // Current list of attendance for current practice
    const [attendanceList, setAttendanceList] = useState<Array<Attend>>([])
    const [openDialogMyPlayers, setOpenDialogMyPlayers] = useState(false)

    function attendance(playerID: string, present: boolean): Attend {
        const player = state.allPlayers.find(x => x.id === playerID)
        const attendance: Attend = {
            playerID: playerID,
            name: player ? `${player.attributes.field_player_first_name} ${player.attributes.field_player_last_name}` : t('AttendanceReport00'),
            present: present
        }
        return attendance
    }

    // Set current list of attendance for current practice
    useEffect(() => {
        setAttendanceList(state.curPractice.attendance.map((item) => attendance(item.playerID, item.present)))
    }, [state.curPractice]) // rerender when new practice is saved so we start with attendance list with all players seleccted

    // If player deleted in MyPlayers then remove player from attendance list
    useEffect(() => {
        // Don't run initially because that would empty the attendance list
        if (attendanceList.length !== 0)
            setAttendanceList(attendanceList.filter(x => state.allPlayers.find(y => y.id === x.playerID)))
    }, [state.allPlayers])

    // Close dialog with player selection, save team with new player list and set but not save new attendance list for current practice
    async function onCloseDialogMyPlayers(actOnSelected: boolean) {
        setOpenDialogMyPlayers(false)

        // Get and reset selections from CRUDList. Base newPlayerList of state.allPlayers so we don't risk using a player that was not saved in DialogMyPlayers
        const newPlayerList = state.allPlayers.filter(x => state.CRUDListSelectedValues && state.CRUDListSelectedValues.includes(x.id!))
        dispatch({ type: 'setCRUDListSelectedValues', selectedValues: [] })

        // update player list for current team and current attendance list
        if (actOnSelected) {
            // Update team related to current practice - set players on the team
            const teamNode = {
                id: state.curPractice.team?.id,
                type: 'node--team',
                relationships: {
                    field_players: {
                        data: newPlayerList.map(x => { return { type: 'node--player', id: x.id } })
                    }
                }
            }
            const resp = await nodeCRUD(state, dispatch, CRUD.Update, teamNode)
            if (!resp.data) {
                dispatch(getActionSetConfirm(resp));
                return
            }

            // Get all players that are in attendance list and newTeamList
            let attendanceListLocal = attendanceList.filter(x => newPlayerList.find(y => y.id === x.playerID))
            // Get all new players and set their attendance to true
            attendanceListLocal = attendanceListLocal.concat(newPlayerList
                .filter(x => !attendanceList.find(y => y.playerID === x.id))
                .map((z) => attendance(z.id!, true)))
            // make sure current practice reflects the updated team
            const action: ActionSetPractice = {
                type: 'setPractice', practice: {
                    ...state.curPractice,
                    team: resp.data,
                    attendance: attendanceListLocal,
                }
            }
            dispatch(action)
        } else {
            // In the case where player name was changed
            setAttendanceList(attendanceList.map(x => attendance(x.playerID, x.present)))
        }
    }

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                PaperProps={{
                    style: {
                        borderRadius: 16,
                        width: '95%',
                        maxWidth: '400px'
                    },
                }}
            >

                <ICBDialogTitle onClose={props.onClose} title={t("AttendanceReport11")} />

                <Divider style={{ width: '90%', margin: 'auto' }} />

                <DialogContent>
                    <Grid2 container marginLeft={0} marginBottom={0} alignItems="center">
                        {
                            attendanceList.length > 0 ? (
                                attendanceList.map((item, index) => {
                                    return (
                                        <Fragment key={index}>
                                            <Grid2 xs={9}>
                                                <Typography fontSize="14px">
                                                    {item.name}
                                                </Typography>
                                            </Grid2>
                                            <Grid2 xs={3} container justifyContent="flex-end">
                                                <Checkbox
                                                    style={{}}
                                                    checked={item.present}
                                                    onChange={() => {
                                                        var localAttendanceList = [...attendanceList]
                                                        localAttendanceList[index].present = !localAttendanceList[index].present
                                                        setAttendanceList(localAttendanceList)
                                                    }}
                                                    // Unexpectedly I need the styling below. Otherwise, the checkbox is not visible when disabled
                                                    sx={{
                                                        "&.Mui-disabled": {
                                                            color: "grey.500", // Use appropriate color from your theme
                                                        },
                                                    }}
                                                    disabled={state.user.data.id !== state.curPractice.uid}
                                                />
                                            </Grid2>
                                            {index < attendanceList.length - 1 && <Divider style={{ width: '100%' }} />}
                                        </Fragment>
                                    )
                                })
                            ) : (
                                <Typography
                                    fontSize="small"
                                    textAlign="center"
                                    sx={{ color: theme.palette.text.secondary }}
                                >
                                    {state.user.data.id === state.curPractice.uid ?
                                        t('DialogAttendanceListForPractice01')
                                        : t('DialogAttendanceListForPractice03')}
                                </Typography>
                            )
                        }
                    </Grid2>
                </DialogContent>

                {
                    // Only show dialog acitons if the practice belongs to the current user - that might not be the case for clubadmins
                    state.user.data.id === state.curPractice.uid
                    && <DialogActions sx={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: 10 }}>
                        {/* Save attendance list */}
                        <Button
                            color='primary'
                            variant="outlined"
                            sx={{ textTransform: 'none' }}
                            onClick={async () => {
                                // Warn that save of practice will delete info about use of a now deleted player
                                if (attendanceList.find(x => !state.allPlayers.find(y => y.id === x.playerID))) {
                                    dispatch(getActionSetConfirm(
                                        t('when you save you loose information about players that were at the practice but since were deleted'),
                                        t('OK'),
                                        async () => {
                                            const resp = await savePractice(state, dispatch, { ...state.curPractice, attendance: attendanceList })
                                            if (resp) {
                                                // error saving practice
                                                log.error(resp);
                                                dispatch(getActionSetConfirm(resp));
                                            } else {
                                                props.onClose()
                                            }
                                        }));
                                } else {
                                    const resp = await savePractice(state, dispatch, { ...state.curPractice, attendance: attendanceList })
                                    if (resp) {
                                        // error saving practice
                                        log.error(resp);
                                        dispatch(getActionSetConfirm(resp));
                                    } else {
                                        props.onClose()
                                        // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                                        setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 2500)
                                    }
                                }
                            }}
                        >
                            {t('DialogAttendanceListForPractice00')}
                        </Button>

                        {/* Open list of players to change list of players on the team */}
                        <Button
                            //variant='outlined'
                            sx={{ position: 'absolute', right: 15, height: '30px' }}
                            onClick={() => {
                                // Make player list come up with a selection of the players on the team
                                // Remember that some of the players on the team might be deleted and we don't want those players selected
                                // in the CRUDList. CRUDList will delete those IDs at delete row and/or select row!
                                dispatch({ type: 'setCRUDListSelectedValues', selectedValues: attendanceList.filter(x => state.allPlayers.find(y => y.id === x.playerID)).map((item) => item.playerID) })
                                setOpenDialogMyPlayers(true)
                            }}
                        >
                            <GroupAddOutlinedIcon />
                        </Button>

                    </DialogActions>
                }

            </Dialog>

            {/* Include dialog to change list of players on team */}
            <DialogMyPlayers
                open={openDialogMyPlayers}
                onClose={(actOnSelected: boolean) => onCloseDialogMyPlayers(actOnSelected)}
            />

            <SnackbarMessages
                message={t('DialogAttendanceListForPractice02')}
                open={openSnackbarMessage}
                icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />
        </>
    )
}