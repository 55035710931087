// TODO: This is a copy of DrawerLandscape.tsx. We need to refactor it to be more generic
// and use the same code for both DrawerLandscape and MainMenu.

import { Drawer, Divider, Box, Avatar, Typography, ListItemButton, IconButton, Tooltip } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material";
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';
import { getImageURL, logout, reloadApp } from "../misc/Functions";
import { Context } from "../App";
import { ActionSetOperationMode, OperationMode, TypeContext } from "../misc/Types";
import { CDN, DRUPALENTITYINIT } from "../misc/Constants";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import SortByAlphaRoundedIcon from '@mui/icons-material/SortByAlphaRounded';
import GroupWorkOutlinedIcon from '@mui/icons-material/GroupWorkOutlined';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import Groups2 from '@mui/icons-material/Groups2';
import AccountCircleRounded from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import SportsBasketballRoundedIcon from '@mui/icons-material/SportsBasketballRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';

export interface PropsMenuMain {
    open: boolean,
    onClose: () => any,
    anchorEl: null | HTMLElement,
}

export function MenuMain(props: PropsMenuMain) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { open, onClose } = props;
    const theme = useTheme();
    const { state, dispatch } = useContext(Context) as TypeContext;
    const [selectedPath, setSelectedPath] = useState<string | null>(location.pathname);

    const [, setDrawerOpen] = useState(true);

    const fileIDAuthorImage = state.user.data.relationships?.user_picture?.data?.id;
    const authorImage = fileIDAuthorImage && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        props.onClose();
    };

    useEffect(() => {
        setSelectedPath(location.pathname);
    }, [location.pathname]);

    const firstSection: any[] = [
        // Drills
        {
            text: t('DrawerLandscape01'),
            icon: <SportsBasketballOutlinedIcon />,
            selectedIcon: <SportsBasketballRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
            action0: () => {
                let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.exercise }
                dispatch(action);
                navigate('/icbdrills');
                handleDrawerClose();
            },
            action1: () => {
                let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.exercise }
                dispatch(action);
                navigate('/exercisecreate');
                handleDrawerClose();
            },
            tooltipCreate: t('DrawerLandscape04'),
            createButtonColor: theme.palette.secondary.main,
            path: '/icbdrills',
            isVisible: true,
        },

        // Practices
        {
            text: t('DrawerLandscape02'),
            icon: <FolderOpenOutlinedIcon />,
            selectedIcon: <FolderRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
            action0: () => {
                navigate('/practices');
                handleDrawerClose();
            },
            path: '/practices',
            isVisible: true,
            action1: () => {
                navigate('/icbdrills');
                dispatch({ type: 'showPracticeProgram', show: true });
                dispatch({ type: 'setOperationMode', operationMode: OperationMode.exercise });
                handleDrawerClose();
            },
            tooltipCreate: t('Exercises01'),
            createButtonColor: theme.palette.secondary.main,
        },

        // Plays
        {
            text: t('DrawerLandscape14'),
            icon: (
                <img
                    src={theme.palette.mode === 'dark' ? '/images/playbuilder/PlaysIconWhite.svg' : '/images/playbuilder/PlaysIcon.svg'}
                    alt="Prácticas"
                    style={{ width: '24px', height: '24px' }}
                />
            ),
            selectedIcon: (
                <img
                    src={theme.palette.mode === 'dark' ? '/images/playbuilder/PlaysIconWhite.svg' : '/images/playbuilder/PlaysIconBlue.svg'}
                    alt="Prácticas"
                    style={{ width: '24px', height: '24px' }}
                />
            ),
            action0: () => {
                let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.play }
                dispatch(action);
                navigate('/icbplays');
                handleDrawerClose();
            },
            action1: () => {
                // navigate('/icbdrills')
                let action: ActionSetOperationMode = { type: 'setOperationMode', operationMode: OperationMode.play }
                dispatch(action);
                dispatch({ type: 'setPlay', play: DRUPALENTITYINIT });
                navigate('/createplay');
                handleDrawerClose();
            },
            tooltipCreate: t('ICBAppBar13'),
            createButtonColor: theme.palette.primary.main,
            path: '/icbplays',
            isVisible: true,
        },

        //Playbooks
        {
            text: t('DrawerLandscape15'),
            icon: <ArticleOutlinedIcon />, // Replace with your desired icon
            selectedIcon: <ArticleIcon sx={{ color: theme.palette.primary.main }} />,
            action0: () => {
                navigate('/myplaybooks');
                handleDrawerClose();
            },
            path: '/playbook',
            isVisible: true,
            action1: () => {
                dispatch({ type: 'setPlaybook', playbook: DRUPALENTITYINIT });
                dispatch({ type: 'setOperationMode', operationMode: OperationMode.play });
                dispatch({ type: 'showPracticeProgram', show: true });
                navigate('/icbplays');
                handleDrawerClose();
            },
            tooltipCreate: t('ICBAppBar14'),
            createButtonColor: theme.palette.primary.main,
            divider: true
        },
    ]

    const secondSection: any[] = [
        //Concepts
        {
            text: t('DrawerLandscape13'),
            icon: <LightbulbOutlinedIcon />,
            selectedIcon: <LightbulbIcon sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/concepts');
                handleDrawerClose();
            },
            path: '/concepts',
            isVisible: true,
            color: theme.palette.secondary.main,
            divider: false
        },
        //Reports
        {
            text: t('DrawerLandscape03'),
            icon: <InsertChartOutlinedIcon />,
            selectedIcon: <InsertChartIcon sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/formgetpracticereport');
                handleDrawerClose();
            },
            path: '/formgetpracticereport',
            isVisible: true,
            color: theme.palette.secondary.main,
        },
        //Club documents
        {
            text: t('DrawerLandscape11'),
            icon: <ArticleOutlinedIcon />,
            selectedIcon: <ArticleIcon sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/clubdocuments');
                handleDrawerClose();
            },
            path: '/clubdocuments',
            isVisible: ['clubadmin', 'club'].includes(state.user.data.attributes.field_user_type),
            color: theme.palette.secondary.main,

        },
        //Categories
        {
            text: t('DrawerLandscape05'),
            icon: <SortByAlphaRoundedIcon />,
            selectedIcon: <SortByAlphaRoundedIcon sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/groups');
                handleDrawerClose();
            },
            path: '/groups',
            isVisible: ['pro', 'clubadmin'].includes(state.user.data.attributes.field_user_type),
            color: theme.palette.secondary.main,
        },
        //My teams
        {
            text: t('DrawerLandscape10'),
            icon: <GroupWorkOutlinedIcon />,
            selectedIcon: <GroupWorkIcon sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/myteams');
                handleDrawerClose();
            },
            path: '/myteams',
            isVisible: true,
            color: theme.palette.secondary.main,
        },
        //Coaches
        {
            text: t('DrawerLandscape06'),
            icon: <GroupsOutlinedIcon />,
            selectedIcon: <Groups2 sx={{ color: theme.palette.secondary.main }} />,
            action: () => {
                navigate('/coaches');
                handleDrawerClose();
            },
            path: '/coaches',
            isVisible: state.user.data.attributes.field_user_type === 'clubadmin',
            color: theme.palette.secondary.main,
        },
    ];

    return (
        <>
            <Drawer
                anchor="left"
                id="dropDownMenuActions"
                open={open}
                onClose={onClose}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%', // Asegura que el Drawer ocupe toda la altura
                    '& .MuiDrawer-paper': {
                        minWidth: 250,
                        maxWidth: '80%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    },
                }}
            >
                <Box sx={{ padding: 2, flexGrow: 1 }}>
                    {/* Logo */}
                    <Box mt="10px" ml="10px">
                        <img
                            height={42}
                            src={getImageURL(theme.palette.mode === 'dark' ? 'logoICBWhite.webp' : 'logoICBBlue.webp')}
                            alt="Logo"
                        />
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#f9f9f9', borderRadius: 2, padding: 1, marginTop: 2, marginBottom: 1 }}>
                        {
                            authorImage ?
                                <img
                                    src={`${CDN}/${authorImage}.webp`}
                                    height="32"
                                    width="32"
                                    style={{ borderRadius: "50%", height: '36px', width: '36px', marginRight: '8px' }}
                                    alt="Imagen del Autor"
                                />
                                : <Avatar sx={{ bgcolor: (theme) => theme.palette.secondary.main, width: 32, height: 32, fontSize: "0.9rem", marginRight: '8px' }}>
                                    {state.user.data.attributes.display_name?.substring(0, 2).toUpperCase()}
                                </Avatar>
                        }
                        <Box>
                            <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: 'black' }}>
                                {state.user.data.attributes.field_first_name} {state.user.data.attributes.field_last_name}
                            </Typography>
                            <Typography sx={{ fontSize: '12px', color: 'black' }}>
                                {state.user.data.attributes.field_user_type === 'clubadmin' ? t('MainMenu02') : state.user.data.attributes.field_user_type === 'club' ? t('MainMenu03') : state.user.data.attributes.field_user_type === 'free' ? t('MainMenu00') : t('MainMenu01')}
                            </Typography>
                        </Box>
                    </Box>

                    {/* First section */}
                    {firstSection.map((item, index) => (
                        item.isVisible && (
                            <Box paddingBottom={0.5} key={index} display="flex" alignItems="center">
                                <ListItemButton
                                    onClick={() => {
                                        typeof item.action0 === 'function' ? item.action0() : navigate(item.action0);
                                    }}
                                    selected={selectedPath === item.path}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: open ? 'flex-start' : 'center',
                                        borderRadius: 2,
                                        width: '100%',
                                        padding: '8px',
                                    }}
                                >
                                    {selectedPath === item.path ? item.selectedIcon : item.icon}
                                    {open && (
                                        <Typography
                                            sx={{
                                                fontSize: 14,
                                                whiteSpace: 'nowrap',
                                                marginLeft: 1,
                                                fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                                                color: selectedPath === item.path ? item.createButtonColor : '',
                                            }}
                                        >
                                            {t(item.text)}
                                        </Typography>
                                    )}
                                </ListItemButton>
                                {open && (
                                    <IconButton
                                        onClick={() => {
                                            // Acción del IconButton
                                        }}
                                        aria-label="acción adicional"
                                        sx={{ marginLeft: open ? 1 : 0 }}
                                    >
                                        <Tooltip title={t(item.tooltipCreate)} placement="right">
                                            <AddBoxRoundedIcon
                                                onClick={item.action1}
                                                sx={{ color: item.createButtonColor }}
                                            />
                                        </Tooltip>
                                    </IconButton>
                                )}
                            </Box>
                        )
                    ))}

                    <Divider sx={{ marginBottom: 0.5, marginX: 1 }} />

                    <Box >
                        {secondSection.map((item, index) => (
                            item.isVisible && (
                                <Box paddingBottom={0.5} key={index}>
                                    <ListItemButton
                                        onClick={() => {
                                            typeof item.action === 'function' ? item.action() : navigate(item.action);
                                        }}
                                        selected={selectedPath === item.path}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: open ? 'flex-start' : 'center',
                                            borderRadius: 2,
                                            width: '100%',
                                            padding: '8px',
                                        }}
                                    >
                                        {selectedPath === item.path ? item.selectedIcon : item.icon}
                                        {open && (
                                            <Typography
                                                sx={{
                                                    fontSize: 14,
                                                    whiteSpace: 'nowrap',
                                                    marginLeft: 1,
                                                    fontWeight: selectedPath === item.path ? 'bold' : 'normal',
                                                    color: selectedPath === item.path ? item.color : '',
                                                }}
                                            >
                                                {t(item.text)}
                                            </Typography>
                                        )}
                                    </ListItemButton>
                                    {item.divider && <Divider sx={{ marginTop: 0.5 }} />}
                                </Box>
                            )
                        ))}
                    </Box>
                </Box>

                <Box paddingRight={1} paddingLeft={1} display="flex" alignItems="center">

                    <ListItemButton
                        onClick={() => { reloadApp(state.nativeApp, location.pathname) }}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: open ? 'flex-start' : 'center',
                            borderRadius: 2,
                            width: '100%',
                            padding: '8px',
                        }}
                    >
                        <RefreshIcon />
                        {open && (
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    whiteSpace: 'nowrap',
                                    marginLeft: 1,
                                    fontWeight: selectedPath === '/profile' ? 'bold' : 'normal',
                                }}
                            >
                                {t('MainMenu04')}
                            </Typography>
                        )}
                    </ListItemButton>
                </Box>
                <Box paddingRight={1} paddingLeft={1} paddingBottom={3} display="flex" alignItems="center">
                    <ListItemButton
                        onClick={() => {
                            navigate('/profile');
                            handleDrawerClose();
                        }}
                        selected={selectedPath === '/profile'}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: open ? 'flex-start' : 'center',
                            borderRadius: 2,
                            width: '100%',
                            padding: '8px',
                        }}
                    >
                        {selectedPath === '/profile' ? <AccountCircleRounded sx={{ color: theme.palette.secondary.main }} /> : <AccountCircleRounded />}
                        {open && (
                            <Typography
                                sx={{
                                    fontSize: 14,
                                    whiteSpace: 'nowrap',
                                    marginLeft: 1,
                                    fontWeight: selectedPath === '/profile' ? 'bold' : 'normal',
                                    color: selectedPath === '/profile' ? theme.palette.secondary.main : '',
                                }}
                            >
                                {t('DrawerLandscape07')}
                            </Typography>
                        )}
                    </ListItemButton>
                    {open && (
                        <IconButton
                            onClick={() => logout(state, dispatch, navigate)}
                            aria-label="acción adicional"
                        >
                            <Tooltip title={t('DrawerLandscape09')} placement="right">
                                <LogoutRoundedIcon />
                            </Tooltip>
                        </IconButton>
                    )}
                </Box>
            </Drawer>
        </>
    );
}


