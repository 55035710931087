import { Box, Button, Divider, Grid, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import log from "../misc/Logger";
import { Context } from '../App';
import { ActionSetPlaybook, CRUD, DrupalEntity, JSONAPITypeId, TypeContext } from '../misc/Types';
import { useTranslation } from 'react-i18next';
import { useContext, useState } from 'react';
import { getActionSetConfirm, getDD, getNodeWithUpdatedRelationship, getPlaybookSharable, nodeCRUD } from '../misc/Functions';
import SnackbarMessages from './SnackbarMessages';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import PlayCard from './PlayCard';
import { useTheme } from '@mui/material/styles';
import { BACKEND } from '../misc/Constants';
import DialogPlaybook from './DialogPlaybook';

export default function CreatePlaybook() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('CreatePlaybook');
    const theme = useTheme();

    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)
    const [openDialogPlaybook, setOpenDialogPlaybook] = useState(false);

    const validationSchema = yup.object({
        title: yup
            .string()
            .required(t('CreatePlaybook08')),
        // // allow save if no other node with same title or node with title has same id as the one we try to save
        // .test('plays-in-playbook', t('Playbook has no plays'), () => {
        //     return state.curPlaybook.relationships.field_plays
        //         && state.curPlaybook.relationships.field_plays.data
        //         && state.curPlaybook.relationships.field_plays.data.length > 0
        // }),
        field_playbook_description: yup
            .string(),
    });

    const formik = useFormik({
        enableReinitialize: false,
        initialValues: {
            title: (state.curPlaybook.attributes.title ?? '') as string,
            field_playbook_description: (state.curPlaybook.attributes.field_playbook_description ?? '') as string,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const overwriteID = state.allPlaybooks.find(x => x.attributes.title === values.title)?.id
            if (overwriteID) {
                dispatch(getActionSetConfirm(t('CreatePlaybook09'), 'ok', async () => {
                    savePlaybook(values, overwriteID)
                }));
            } else {
                savePlaybook(values, overwriteID)
            }
        },
    });

    // save playbook. Overwrite existing playbook with id overwriteID if overwriteID has a value
    async function savePlaybook(values: any, overwriteID: string | undefined) {
        // Check playbook has plays
        if (!state.curPlaybook.relationships.field_plays || state.curPlaybook.relationships.field_plays.data.length === 0) {
            dispatch(getActionSetConfirm(t('CreatePlaybook10')));
            return
        }

        // assume new playbook
        let playbookData: DrupalEntity = {
            type: 'node--playbook',
            attributes: {
                title: values.title,
                field_playbook_description: values.field_playbook_description
            },
            relationships: {
                field_plays: state.curPlaybook.relationships.field_plays
            }
        }
        if (overwriteID)
            // overwrite playbook
            playbookData.id = overwriteID
        // save playbook
        nodeCRUD(state, dispatch, overwriteID ? CRUD.Update : CRUD.Create, playbookData)
            .then((resp) => {
                if (resp.data) {
                    // current playbook updated
                    const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: resp.data }
                    dispatch(action)
                    // if current play book already has been published then update published playbook
                    if (resp.data.attributes.field_url_and_credentials) {
                        getDD(state, dispatch, `${BACKEND}/icb-playbook/create/${resp.data.attributes.drupal_internal__nid}`, 'POST', getPlaybookSharable(state, resp.data))
                    }
                    setOpenSnackbarMessage(true);
                    setTimeout(() => { setOpenSnackbarMessage(false); }, 1500)
                    setOpenDialogPlaybook(true)
                } else {
                    dispatch(getActionSetConfirm(resp));
                }
            })
    }

    return (
        <Grid container direction="row" alignItems="stretch" style={{ marginBottom: 150 }} >
            {!state.portrait && (<Divider orientation="vertical" flexItem sx={{ minHeight: '100vh' }} />)}
            <Grid item xs margin={1}> {/* Wrap Card in a Grid item */}

                <Box sx={{ backgroundColor: '#e1f5fe', padding: 0.5, borderRadius: 1, border: '1px solid ' + theme.palette.divider, }}>
                    <Typography textAlign={'center'} sx={{ fontSize: { xs: 20, sm: 23 }, fontFamily: 'PT Sans, sans-serif', fontWeight: 'bold', color: '#15468f' }}>
                        {t('CreatePlaybook02')}
                    </Typography>
                </Box>

                <form onSubmit={formik.handleSubmit}>

                    <TextField
                        size='small'
                        variant='outlined'
                        fullWidth
                        id="title"
                        name="title"
                        label={t('CreatePlaybook03')}
                        value={formik.values.title}
                        onChange={(e) => {
                            formik.handleChange(e);
                            // below is to get value into global state so value is maintained 
                            // when shifting between portrait and landscape and when adding 
                            // plays and checking playbook in portrait mode. Not an
                            // elegang solution
                            const action: ActionSetPlaybook = {
                                type: 'setPlaybook',
                                playbook: {
                                    ...state.curPlaybook,
                                    attributes: {
                                        ...state.curPlaybook.attributes,
                                        title: e.target.value
                                    }
                                }
                            }
                            dispatch(action)
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                        helperText={formik.touched.title && formik.errors.title}
                        sx={{ marginTop: 1 }}
                        inputProps={{ maxLength: 45 }}
                    />

                    <TextField
                        size='small'
                        fullWidth
                        multiline
                        id="field_playbook_description"
                        name="field_playbook_description"
                        label={t('CreatePlaybook04')}
                        value={formik.values.field_playbook_description}
                        onChange={(e) => {
                            formik.handleChange(e);
                            const action: ActionSetPlaybook = {
                                type: 'setPlaybook',
                                playbook: {
                                    ...state.curPlaybook,
                                    attributes: {
                                        ...state.curPlaybook.attributes,
                                        field_playbook_description: e.target.value
                                    }
                                }
                            }
                            dispatch(action)
                        }}
                        onBlur={formik.handleBlur}
                        error={formik.touched.field_playbook_description && Boolean(formik.errors.field_playbook_description)}
                        helperText={formik.touched.field_playbook_description && formik.errors.field_playbook_description}
                        sx={{ marginTop: 1 }}
                        inputProps={{ maxLength: 150 }}
                    />

                    <Grid item xs={12} display={'flex'} justifyContent={'space-evenly'} >

                        {/* Save play */}
                        <Button
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.8rem',
                                height: '25px',
                                color: theme.palette.text.primary,
                                border: '1px solid ' + theme.palette.divider,
                                fontWeight: 'bold',
                                marginTop: 1,
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                }
                            }}
                            type="submit">
                            {t('CreatePlaybook01')}
                        </Button>

                        {/* Clear list of plays */}
                        <Button
                            onClick={() => {
                                dispatch(getActionSetConfirm(t('CreatePlaybook06'), 'ok', () => {
                                    // remove all plays in playbook
                                    const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', []) }
                                    dispatch(action)
                                }));
                            }}
                            sx={{
                                textTransform: 'none',
                                fontSize: '0.8rem',
                                height: '25px',
                                color: theme.palette.text.primary,
                                border: '1px solid ' + theme.palette.divider,
                                fontWeight: 'bold',
                                marginTop: 1,
                                marginBottom: 1,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.selected,
                                }
                            }}>
                            {t('CreatePlaybook05')}
                        </Button>
                    </Grid>

                    {/* List all plays */}
                    {
                        state.curPlaybook.relationships.field_plays &&
                        state.curPlaybook.relationships.field_plays.data.map((item: JSONAPITypeId, index: number) => {
                            return (
                                <PlayCard
                                    key={index}
                                    index={index}
                                    play={state.allPlays.find(x => x.id === item.id)!}
                                />
                            )
                        })
                    }

                    {/* Show msg if there are no plays in the playbook */}
                    {
                        (!state.curPlaybook.relationships.field_plays || state.curPlaybook.relationships.field_plays.data.length === 0) &&
                        <Typography sx={{ paddingTop: 0, color: theme.palette.text.secondary, fontSize: 13, textAlign: 'center' }}>
                            {t('CreatePlaybook07')}
                        </Typography>
                    }

                </form>
            </Grid>

            {/* debug - show form values - <span>{JSON.stringify(formik.values, null, 2)}</span> */}

            <SnackbarMessages
                message={t('CreatePlaybook00')}
                open={openSnackbarMessage}
                icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />

            <DialogPlaybook
                open={openDialogPlaybook}
                onClose={() => setOpenDialogPlaybook(false)}
            />
        </Grid>
    );
}
