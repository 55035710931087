import { Fragment, useContext, useState, } from "react";
import { PropsExerciseCardSelected, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Divider, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import log from "../misc/Logger";
import { exerciseImage } from "../misc/Functions";
import { useTranslation } from "react-i18next";
import { DialogExerciseDetails } from "./DialogExerciseDetails";

export default function ExerciseCardPrint(props: PropsExerciseCardSelected) {
    const { state } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('ExerciseCardPrint()');
    const theme = useTheme();

    const [openDialogExerciseDetails, setOpenDialogExerciseDetails] = useState(false)

    // Proctect agaist "You no longer have access to this exercise"
    const fileIDImage = props.exerciseSelected.exercise?.relationships?.field_exercise_board_image?.data?.id;

    return (
        <Fragment>
            <Divider sx={{ borderColor: theme.palette.divider }} />

            {/* Only include DialogExerciseDetails if we have an exercise */}
            {
                props.exerciseSelected.exercise &&
                <DialogExerciseDetails
                    open={openDialogExerciseDetails}
                    onClose={() => setOpenDialogExerciseDetails(false)}
                    exercise={props.exerciseSelected.exercise}
                    addOption={false}
                />
            }

            <Box display="flex" justifyContent={'space-between'} mt={2} mb={1}>
                <Typography sx={{ fontWeight: 'bold', fontSize: state.portrait ? '13px' : '13px', textTransform: 'uppercase' }}>
                    {props.index! + 1}
                    . {props.exerciseSelected!.exercise?.attributes.title || t('Generel21')}
                </Typography>
                <Typography variant="h6" sx={{ fontSize: state.portrait ? '13px' : '13px', fontWeight: 'bold' }}>
                    {props.startAndStopTime}
                </Typography>
            </Box>

            <Fragment>
                {
                    // Only show exercise image if image is NOT 'boardBlank.webp'
                    fileIDImage &&
                    <img src={exerciseImage(state, fileIDImage, props.exerciseSelected.exercise.id)}
                        onClick={() => setOpenDialogExerciseDetails(true)}
                        //alt="Descripción de la imagen"
                        style={{
                            float: 'left',
                            height: 'auto',
                            maxWidth: '48%',
                            borderRadius: '6px',
                            marginRight: '5px'
                        }} />
                }

                <Box>
                    {
                        // Only show exercise description title if image is NOT 'boardBlank.webp'
                        state.uiSettings.showDescriptionInPortrait && !fileIDImage &&
                        <Typography sx={{ fontSize: state.portrait ? '11px' : '11px', fontWeight: 'bold', textDecoration: 'underline' }}>
                            {t('ExerciseCardPrint00')}
                        </Typography>
                    }

                    {
                        state.uiSettings.showDescriptionInPortrait &&
                        <Typography sx={{ fontSize: '11px', marginTop: '1px', lineHeight: '1.3', whiteSpace: 'pre-wrap', color: theme.palette.text.secondary }}>
                            {props.exerciseSelected!.exercise?.attributes.field_description}
                        </Typography>
                    }

                    {props.exerciseSelected?.coachNote && (
                        <Typography sx={{ fontSize: '11px', marginTop: 1, fontWeight: 'bold', textDecoration: 'underline' }}>
                            {t('ExerciseCardPrint01')}
                        </Typography>
                    )}

                    <Typography sx={{ fontSize: '11px', marginTop: '1px', lineHeight: '1.3', whiteSpace: 'pre-wrap', }}>
                        {props.exerciseSelected?.coachNote}
                    </Typography>
                </Box>
            </Fragment>
        </Fragment >
    );
}
