import { Fragment, useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { nodeCRUD, validateEmail } from "../misc/Functions";
import { CRUD, DataGridActions, DataGridUpdate, DrupalEntity, TypeContext } from "../misc/Types";
import CRUDList from "./CRUDList";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";

interface PropsMyContacts {
    onClose?: (ok: boolean) => void, // call with true if dialog action is to be performed. Call with false if dialog action is cancelled
}

export default function MyContacts(props: PropsMyContacts) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('MyContacts');

    const [rows, setRows] = useState<Array<any>>([])

    useEffect(() => {
        // Parse list of my contacts
        let list: Array<String> = []
        try {
            list = state.user.data.attributes.field_contacts ? JSON.parse(state.user.data.attributes.field_contacts) : []
        } catch {
            log.error('Invalid JSON value in state.user.data.attributes.field_contacts: ' + state.user.data.attributes.field_contacts)
        }
        // setRows(list.map((item, index) => ({ id: index, contact: item })))
        setRows(list.map((item) => ({ id: item, contact: item })))
    }, [state.user.data])

    const columns = () => [
        { ...GRID_CHECKBOX_SELECTION_COL_DEF, width: 10 },
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'contact', headerName: t('MyContacts02'), flex: 1, editable: true, },
    ]

    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                if (dataGridUpdate.newRow && !dataGridUpdate.newRow.contact) {
                    reject(new Error(t('MyContacts01')))
                    return
                }
                // If new contact and we already have new contact then error
                if (dataGridUpdate.newRow && state.user.data.attributes.field_contacts && JSON.parse(state.user.data.attributes.field_contacts.toLowerCase()).includes(dataGridUpdate.newRow.contact.toLowerCase())) {
                    reject(new Error(t('MyContacts00')))
                    return
                }
                // If new contact and new contact not valid email address then error
                if (dataGridUpdate.newRow && !validateEmail(dataGridUpdate.newRow.contact)) {
                    reject(new Error(t('Generel20')))
                    return
                }
                const userData: DrupalEntity = {
                    type: 'user--user',
                    id: state.user.data.id,
                    attributes: {
                        field_contacts: JSON.stringify(dataGridUpdate.rows.map((item) => item.contact))
                    }
                }
                nodeCRUD(state, dispatch, CRUD.Update, userData)
                resolve(dataGridUpdate.delete ? dataGridUpdate.rows : dataGridUpdate.newRow)
            })
    }

    return (
        <Fragment>
            <CRUDList
                rows={rows}
                columns={columns()}
                actions={[DataGridActions.save, DataGridActions.cancel, DataGridActions.delete, DataGridActions.edit, DataGridActions.down, DataGridActions.up]}
                processRowUpdate={processRowUpdate}
                labelAddRow={t('MyContacts03')}
                labelSaveSelection={t('MyContacts07')}
                gridColumnVisibilityModel={{ id: false }}
                columnFocusOnNew="contact"
                onClose={props.onClose}
                deleteWarning={t('MyContacts09')}
                checkboxselection={true}
            />
        </Fragment>
    )
}