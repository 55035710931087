import { Snackbar, Alert, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';
import { lighten } from '@mui/material/styles';

interface PropsSnackbar {
    open: boolean;
    message: string;
    icon: React.ReactNode;
    color: string;
    portraitVerticalPosition?: 'top' | 'bottom';
    portraitHorizontalPosition?: 'center' | 'right';
    landscapeVerticalPosition?: 'top' | 'bottom';
    landscapeHorizontalPosition?: 'center' | 'right';
}

export default function SnackbarMessages({
    open, message, icon, color,
    portraitVerticalPosition = 'bottom',
    portraitHorizontalPosition = 'center',
    landscapeVerticalPosition = 'bottom',
    landscapeHorizontalPosition = 'center'
}: PropsSnackbar) {
    const { state } = useContext(Context) as TypeContext;

    const isExtraSmall = useMediaQuery('(max-width:600px)');

    return (
        <Snackbar
            open={open}
            message={message}
            anchorOrigin={{
                vertical: state.portrait ? portraitVerticalPosition : landscapeVerticalPosition,
                horizontal: state.portrait ? portraitHorizontalPosition : landscapeHorizontalPosition
            }}
            // autoHideDuration={2000}
            sx={{
                maxWidth: '285px',
                marginBottom: state.portrait
                    ? (isExtraSmall
                        ? (state.nativeApp ? '85px' : '65px')
                        : (state.nativeApp ? '75px' : '60px'))
                    : '7px',
                marginRight: landscapeHorizontalPosition === 'right' ? '75px' : '0px'
            }}
        >

            <Alert
                variant="filled"
                icon={icon}
                sx={{
                    width: '100%',
                    borderRadius: '30px',
                    backgroundColor: lighten(color, 0.9),
                    color: color,
                    border: `1px solid ${lighten(color, 0.7)}`,
                    fontSize: '14px'
                }}
            >
                {message}
            </Alert>

        </Snackbar>
    );
}
