import { useContext, useEffect, useState } from "react";
import { ActionSetExerciseIDLastCRUD, ActionSetSelectedExercisePool, ActionSetSelectedGroup, CRUD, DrupalEntity, OperationMode, TypeContext, UserType } from "../misc/Types";
import { Context } from "../App";
import { Box, Button, CircularProgress, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { exerciseImage, exerciseVideo, getActionSetConfirm, getAllContentEntities, getDD, nodeCRUD } from "../misc/Functions";
import { useLocation, useNavigate, } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";
import { Suspense, lazy } from 'react'
import log from "../misc/Logger";
import mime from 'mime';
import DialogGetSingleLineOfText from "./DialogGetSingleLineOfText";
import { BACKEND, IMAGEMIMETYPES, SHOW_ALL_GROUP, VIDEOMIMETYPES } from "../misc/Constants";
// const Board = lazy(() => import('./Board'))
const Board = lazy(() =>
    //.catch() use example
    import('./Board').catch((error) => {
        console.error('Failed to load Board:', error);
        //mandatory return
        return { default: () => <div>Error loading Board. Try relogin.</div> };
    })
);

interface TypeExercise {
    title: string,
    field_description: string,
    field_group: string,
}

enum DialogAction {
    save = 'save',
    cancel = 'cancel',
    delete = 'delete'
}

export default function ExerciseCreate() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    // Get exercise passed when users selected to edit exercise from component ExerciseCard
    const stateUseLocation = useLocation();
    const exercise: DrupalEntity = stateUseLocation.state;

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [videoFile, setVideoFile] = useState<File | null>(null);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    // const [enableButtonsAddAndDelete, setEnableButtonsAddAndDelete] = useState(false);
    // Set the current exercise image to the full path of the image or null if no image 
    // const [imageURL, setImageURL] = useState<string | null>(
    //     fileData2FileURL(state, exercise?.relationships.field_exercise_board_image.data) && 
    //     `${BACKEND}${fileData2FileURL(state, exercise?.relationships.field_exercise_board_image.data)}`);

    const [imageURL, setImageURL] = useState<string | null>();
    const [videoURL, setVideoURL] = useState<string | null>();
    const [openDialogGetSingleLineOfText, setOpenDialogGetSingleLineOfText] = useState(false)
    const [formData, setFormData] = useState<TypeExercise>(getFormdata())
    const [loading, setLoading] = useState(false);

    // If refresh/F5 then make sure image is shown when files are retrieved AND group is set when groups are retrieved
    useEffect(() => {
        if (!imageURL)
            setImageURL(exercise?.relationships.field_exercise_board_image.data?.id && exerciseImage(state, exercise?.relationships.field_exercise_board_image.data?.id))
    }, [state.allFiles])
    useEffect(() => {
        if (!videoURL)
            setVideoURL(exercise?.relationships.field_exercise_video.data?.id && exerciseVideo(state, exercise?.relationships.field_exercise_video.data?.id));
    }, [state.allFiles])

    function getFormdata(): TypeExercise {
        return {
            title: exercise ? exercise.attributes.title : '',
            field_description: exercise ? exercise.attributes.field_description : '',
            field_group:
                // If there is an exercise we can still have a situation where there is no group. Because the group
                // might have been deleted by the clubadmin (Club Admin Defined Group) In this case we set the group to
                // 'group delete by clubadmin' to ensure that the control showing the group is 'controlled' and the 
                // user knows what has happened!
                // exercise ? state.allGroups.find(x => x.id === exercise.relationships.field_group.data.id)?.attributes.title || 'group delete by clubadmin' : '',
                (exercise && state.allGroups.find(x => x.id === exercise.relationships.field_group.data.id)?.attributes.title) || '',
        }
    }

    const buttons = [
        { id: 'save', label: t('ExerciseCreate11'), action: DialogAction.save, show: true },
        // { id: 'cancel', label: t('ExerciseCreate12'), action: DialogAction.cancel, show: true },
        { id: 'delete', label: t('ExerciseCreate13'), action: DialogAction.delete, show: exercise !== null },
    ]

    const handleChange = (event: { target: { name: any; value: any; }; }) => {
        let { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            if (['image/png', 'image/gif', 'image/jpg', 'image/jpeg',].includes(event.target.files[0].type)) {
                if (event.target.files[0].size > 8000000)
                    dispatch(getActionSetConfirm(t('AlertMsg27')));
                else {
                    setImageFile(event.target.files[0]);
                    const url = URL.createObjectURL(event.target.files[0]);
                    setImageURL(url);
                }
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg26')));
            }
        }
    };

    function handleVideoFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        log.debug('handleVideoFileChange, upload video type: ' + event.target.files![0].type + '. Name: ' + event.target.files![0].name + '. Size: ' + event.target.files![0].size);
        if (event.target.files && event.target.files.length > 0) {
            const mimeType = mime.getType(event.target.files[0].name) || ''
            if (VIDEOMIMETYPES.includes(mimeType)) { //    event.target.files[0].type))
                // Size limit. Set both upload_max_filesize and post_max_size in /etc/php/8.1/fpm/php.ini
                if (event.target.files[0].size > 128000000)// 8000000)
                    dispatch(getActionSetConfirm(t('AlertMsg28')));
                else {
                    setVideoFile(event.target.files[0]);
                    const url = URL.createObjectURL(event.target.files[0]);
                    setVideoURL(url);
                }
            } else {
                dispatch(getActionSetConfirm(t('AlertMsg25')));
            }
        }
    };

    async function submit(action: DialogAction) {
        log.info('submit: ' + action);
        switch (action) {
            case DialogAction.save:
                if (state.user.login.current_user.uid === 0) {
                    // Anonymous user tries to save an exercise
                    dispatch(getActionSetConfirm(t('Generel00'), t('AccountCaptcha08'), () => navigate('/signup')));
                } else if (
                    !exercise &&
                    state.user.data.attributes.field_user_type === "free" &&
                    state.allExercises.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= state.configuration[0].attributes.field_max_exercises_free_user
                ) {
                    dispatch(getActionSetConfirm(t('AlertMsg12'), t('ICBAppBar04'), () => navigate('/setsubscription')));
                } else {
                    // Club Admin Defined Group
                    if (formData.field_group && !state.allGroups.find(x => x.attributes.title === formData.field_group)) {
                        let msg = 'Add group before using group';
                        if (state.user.data.attributes.field_user_type !== 'clubadmin') {
                            msg = 'Select valid group';
                        }
                        dispatch(getActionSetConfirm(msg));
                        return;
                    }
                    // Check title and description
                    if (formData.title.trim() === '' || formData.field_description.trim() === '' || !formData.field_group) {
                        dispatch(getActionSetConfirm('Specify title, description and group'));
                        return;
                    }

                    dispatch({ type: 'setBackdrop', diff: 1 })

                    // Find selected exercise group
                    let group = state.allGroups.find(item => item.attributes.title === formData.field_group);
                    let groupRelation = { data: { type: group?.type, id: group?.id } };

                    let exerciseData: DrupalEntity = {
                        type: 'node--exercise',
                        attributes: {
                            title: formData.title,
                            field_description: formData.field_description,
                            field_discontinued: exercise ? exercise.attributes.field_discontinued : undefined,
                        },
                        relationships: {
                            field_group: groupRelation,
                        }
                    }
                    if (exercise) {
                        exerciseData.id = exercise.id
                    }

                    const node = await nodeCRUD(state, dispatch, exercise ? CRUD.Update : CRUD.Create, exerciseData)
                    if (!node.data) {
                        // Error
                        dispatch(getActionSetConfirm(node));
                        dispatch({ type: 'setBackdrop', diff: 0 })
                        setButtonsDisabled(false);
                        return
                    }

                    let action: ActionSetExerciseIDLastCRUD = { type: 'setExerciseIDLastCRUD', id: node.data.id }
                    dispatch(action);

                    // Handle exercise image
                    if (imageFile || exercise?.relationships.field_exercise_board_image.data?.id) {
                        // insert/update exercise image or remove existing exercise image
                        let exerciseImageFile
                        if (imageFile) {
                            // insert/update exercise image
                            exerciseImageFile = await nodeCRUD(state, dispatch, CRUD.Update, {
                                type: 'node--exercise',
                                id: node.data.id,
                            }, imageFile ? imageFile : undefined, 'field_exercise_board_image')
                        } else if (!imageURL) {
                            // remove existing exercise image
                            exerciseImageFile = await nodeCRUD(state, dispatch, CRUD.Update, {
                                type: 'node--exercise',
                                id: node.data.id,
                                relationships: {
                                    field_exercise_board_image: {}
                                }
                            })
                        }
                        if (exerciseImageFile && !exerciseImageFile.data) {
                            // Error
                            dispatch(getActionSetConfirm(exerciseImageFile));
                            dispatch({ type: 'setBackdrop', diff: 0 })
                            setButtonsDisabled(false);
                            return
                        }
                    }

                    // Handle exercise video
                    if (videoFile || exercise?.relationships.field_exercise_video.data?.id) {
                        // insert/update exercise video or remove existing exercise video
                        let exerciseVideoFile
                        if (videoFile) {
                            // insert/update exercise video
                            exerciseVideoFile = await nodeCRUD(state, dispatch, CRUD.Update, {
                                type: 'node--exercise',
                                id: node.data.id,
                            }, videoFile ? videoFile : undefined, 'field_exercise_video')
                        } else if (!videoURL) {
                            // remove existing exercise video
                            exerciseVideoFile = await nodeCRUD(state, dispatch, CRUD.Update, {
                                type: 'node--exercise',
                                id: node.data.id,
                                relationships: {
                                    field_exercise_video: {}
                                }
                            })
                        }
                        if (exerciseVideoFile && !exerciseVideoFile.data) {
                            // Error
                            dispatch(getActionSetConfirm(exerciseVideoFile));
                            dispatch({ type: 'setBackdrop', diff: 0 })
                            setButtonsDisabled(false);
                            return
                        }
                    }

                    // re-fetch the node to get propper relations, ie. exercise image and video
                    getAllContentEntities(state, dispatch, 'node--exercise', `?filter[id]=${node.data.id}&include=field_exercise_board_image,field_exercise_video`, false)

                    // If club user creates exercise then exercise must be cloned to his/her club admin
                    // if (!exercise && state.user.data.attributes.field_user_type === 'club' && state.user.data.attributes.field_club_admin_accept) {
                    //     const resp = await icbControllerGenerel02(state, {
                    //         "opr": "cloneExerciseToClubAdmin",
                    //         "nid_src": node.data.attributes.drupal_internal__nid,
                    //     }
                    //     )
                    //     if (!resp.ok) {
                    //         dispatch(getActionSetConfirm(resp.error))
                    //         dispatch({ type: 'setBackdrop', diff: 0 })
                    //         return
                    //     }
                    //     // fetch the exercise that was just cloned to club users club admin
                    //     let filter = `?filter[nid]=${resp.nid_dest}&include=field_exercise_board_image,field_exercise_video`;
                    //     getAllContentEntities(state, dispatch, 'node--exercise', filter, false)
                    // }
                    if (!exercise // no exercise - that is we create an exercise!
                        && state.user.data.attributes.field_user_type === UserType.club
                        && state.user.data.attributes.field_club_admin_accept) {
                        getDD(state, dispatch, `${BACKEND}/icb-node/clone-node-to-clubadmin/${node.data.attributes.drupal_internal__nid}`, 'GET')
                            .then((resp) => {
                                getAllContentEntities(state, dispatch, 'node--exercise', `?filter[nid]=${resp.nid_dest}`, false) // fetch cloned exercise
                            })
                    }

                    setButtonsDisabled(false);

                    // Try to go where new exercise is and show exercise to user!
                    let actionSetSelectedExercisePool: ActionSetSelectedExercisePool = {
                        type: 'setSelectedExercisePool',
                        pool: state.user.data.attributes.field_user_type === UserType.clubadmin ? 'club' : 'mine'
                    }
                    dispatch(actionSetSelectedExercisePool);
                    let actionSetSelectedGroup: ActionSetSelectedGroup = { type: 'setSelectedGroup', group: group! };
                    dispatch(actionSetSelectedGroup);
                    // window.scrollTo(0, 0);
                    navigate('/icbdrills');
                    // dispatch(getActionSetConfirm(t('ExerciseCreate10')));
                    // Close practice program in portrait so user is able to see his new exercise
                    if (state.showPracticeProgram && state.portrait) {
                        let action = { type: 'showPracticeProgram', show: !state.showPracticeProgram };
                        dispatch(action);
                    }

                    dispatch({ type: 'setBackdrop', diff: 0 })
                }
                break;
            case DialogAction.cancel:
                navigate(-1)
                break;
            case DialogAction.delete:
                dispatch(getActionSetConfirm(t('ExerciseCreate18'), 'ok', async () => {
                    await nodeCRUD(state, dispatch, CRUD.Delete, { //.Update, {
                        type: 'node--exercise',
                        id: exercise.id,
                        // attributes: {
                        //     field_discontinued: true,
                        // }
                    })
                    // if (!resp.data) {
                    //     dispatch(getActionSetConfirm(resp));
                    //     return
                    // }
                    // Exercise deleted
                    // dispatch({type: 'exerciseDiscontinue', exerciseID: exercise.id});
                    navigate(-1)
                }));
                break;
        }
    }

    // Close dialog to confirm cancel account
    async function onCloseDialogGetSingleLineOfText(ok: boolean, text: string) {
        if (ok) {
            // User clicked OK to create new exercise group
            if (state.allGroups.find(x => x.attributes.title === text)) {
                dispatch(getActionSetConfirm('ExerciseCreate19'));
            } else {
                // Create group
                const group = {
                    "type": "node--group",
                    "attributes": {
                        "title": text,
                        "field_group_type": OperationMode.exercise,
                    },
                }
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, group);
                if (!resp.data) {
                    dispatch(getActionSetConfirm(resp));
                    return
                }
                // Insert new group in form
                handleChange({ target: { name: "field_group", value: resp.data.attributes.title } })

            }
        }
        setOpenDialogGetSingleLineOfText(false)
    }

    return (
        <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
            <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.secondary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                {t('ExerciseCreate10')}
            </Typography>
            <Divider />

            <Grid2 container margin={'auto'} paddingTop={2}>
                <Grid2 xs={12} md={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2, flexDirection: 'column' }}>
                        {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                        {/* Title */}
                        <TextField
                            name={'title'}
                            label={t('ExerciseCreate00')}
                            value={formData['title']}
                            onChange={handleChange}
                            variant="outlined"
                            inputProps={{ maxLength: 45 }}
                            size="small"
                            helperText={`${formData['title'].length}/45`} // Muestra el conteo de caracteres
                            sx={{ width: '100%' }}
                            InputProps={{
                                style: {
                                    fontWeight: 'bold',
                                    fontFamily: 'PT Sans, sans-serif',
                                    color: theme.palette.primary.main
                                }
                            }}
                        />
                        {/* Description */}

                        <TextField
                            name={'field_description'}
                            label={t('ExerciseCreate01')}
                            value={formData['field_description']}
                            multiline
                            onChange={handleChange}
                            sx={{
                                marginTop: 2,
                            }}
                            inputProps={{ maxLength: 600 }}
                            variant="outlined"
                            size="small"
                            helperText={`${formData['field_description'].length}/600`}
                            InputProps={{
                                style: {
                                    fontFamily: 'PT Sans, sans-serif',
                                    color: theme.palette.primary.main,
                                }
                            }}
                            minRows={3} // Set minimum rows to make it appear taller
                        />

                        {/* Group drowdown */}
                        <Box sx={{ display: 'flex', marginTop: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={[UserType.clubadmin].includes(state.user.data.attributes.field_user_type) ? 9 : 12}>
                                    <FormControl fullWidth>
                                        <InputLabel id="label-group">{t('ExerciseCreate02')}</InputLabel>
                                        <Select
                                            aria-label="exercisecreate_selectexercise"
                                            labelId="label-group"
                                            id="id-group"
                                            value={formData['field_group']}
                                            label={t('ExerciseCreate02')}
                                            name='field_group'
                                            onChange={handleChange}
                                            variant="outlined"
                                            size="small"
                                        >
                                            {state.allGroups.filter(x => x.id !== SHOW_ALL_GROUP && x.attributes.field_group_type === OperationMode.exercise)
                                                .map(x => (<MenuItem key={x.id} value={x.attributes.title}>{x.attributes.title}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3} marginTop={0}  >
                                    {[UserType.clubadmin].includes(state.user.data.attributes.field_user_type) && (
                                        <Button fullWidth sx={{ height: '40px' }} variant="outlined" onClick={() => setOpenDialogGetSingleLineOfText(true)}>
                                            {t('ExerciseCreate14')}
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>

                    <Grid2 sx={{ marginTop: 2 }}>
                        <Suspense fallback={<div> {t('ExerciseCreate17')}</div>}>
                            <Board
                                handlingScreenshot={(disabled: boolean) => {
                                    setButtonsDisabled(disabled);
                                }}
                                setLoading={setLoading} // Pasar la función para controlar el estado de carga
                            />
                        </Suspense>
                    </Grid2>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                        {/* Image */}
                        {/* Primer conjunto de botones */}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, width: '100%' }}>
                            <Button
                                key={'imagefile'}
                                variant="outlined"
                                component="label"
                                color="secondary"
                                size="small"
                                sx={{ width: '100%' }}  // Esto hace que el botón ocupe todo el ancho del contenedor padre
                            >
                                {imageURL ? t('ExerciseCreate04') : t('ExerciseCreate03')}
                                <input
                                    type="file"
                                    id="upload"
                                    accept={IMAGEMIMETYPES.toString()}
                                    hidden
                                    onChange={handleFileChange}
                                />
                            </Button>

                            {/* Remove image */}
                            {imageURL && (
                                <IconButton
                                    sx={{ ml: 1 }}
                                    onClick={() => {
                                        setImageFile(null);
                                        setImageURL(null);
                                        let input = document.getElementById('upload') as HTMLInputElement;
                                        input.value = '';
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>

                        {/* Uploaded image or image from our Board tool */}
                        {imageURL && (
                            <Box sx={{ m: 1 }}>
                                <img src={`${imageURL}`} alt="Uploaded Preview" style={{ width: '150px', height: '150px', marginTop: '1rem' }} />
                            </Box>
                        )}

                        {loading &&
                            <div style={{ paddingTop: 10 }} >
                                <CircularProgress size={25}
                                />
                            </div>
                        }

                        {/* Video */}
                        {/* Segundo conjunto de botones */}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 15, width: '100%' }}>
                            <Button
                                key={'videofile'}
                                variant="outlined"
                                component="label"
                                size="small"
                                color="secondary"

                                sx={{ width: '100%' }}
                            >
                                {/* Change video OR Upload video */}
                                {videoFile ? t('ExerciseCreate06') : t('ExerciseCreate05')}
                                <input
                                    type="file"
                                    id="uploadVideoFile"
                                    accept={VIDEOMIMETYPES.toString()}
                                    hidden
                                    // accept="video/*" with this restriction desktop version does not find mov files
                                    onChange={(event) => handleVideoFileChange(event)}
                                />
                            </Button>

                            {videoURL && (
                                <IconButton
                                    onClick={() => {
                                        setVideoFile(null);
                                        let input = document.getElementById('uploadVideoFile') as HTMLInputElement;
                                        input.value = '';
                                        setVideoURL(null);
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            )}
                        </div>

                        {videoURL && (<Box sx={{ m: 1 }}>{videoFile?.name}</Box>)}
                        {videoURL && (
                            <Box sx={{ m: 1 }}>
                                <video src={videoURL}
                                    width="250"
                                    loop
                                    muted
                                    controls
                                    controlsList="nodownload"
                                />
                            </Box>
                        )}

                        {/* Divider */}
                        <div style={{ height: '5px', backgroundColor: '#ccc', borderRadius: 10, margin: '10px auto' }}> </div>

                        {/* Tercer conjunto de botones */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 15 }}>
                            {buttons.filter(x => x.show).map((item) => (
                                <Button
                                    key={item.label}
                                    onClick={() => { submit(item.action) }}
                                    disabled={buttonsDisabled}
                                    color="secondary"
                                    size="small"
                                    variant={item.action === DialogAction.delete ? 'outlined' : 'contained'}
                                    style={{
                                        textTransform: 'none',
                                        fontWeight: 'bold',
                                        marginLeft: item.action === DialogAction.delete ? '10px' : 0, // Añade un margen izquierdo solo al botón SAVE
                                    }}
                                >
                                    {item.label}
                                </Button>
                            ))}
                        </div>
                    </div>
                </Grid2>
            </Grid2>

            {/* <Typography style={{ marginTop: 10, fontSize: '10px', color: 'darkgrey' }}>
                {t('AlertMsg30')}
            </Typography> */}

            {/* Dialogbox to add new exercise group */}
            <DialogGetSingleLineOfText
                open={openDialogGetSingleLineOfText}
                onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                title={t('ExerciseCreate14')}
                contentLine1={t('ExerciseCreate16')}
                label={t('ExerciseCreate15')}
                button={t('ExerciseCreate07')}
            />
        </Box>
    )
}