import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker, } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import 'dayjs/locale/ca';
import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { ActionSetPractice, Attend, CRUD, DrupalEntity, JSONAPITypeId, PracticeBetter, TypeContext } from "../misc/Types";
import dayjs, { Dayjs } from "dayjs";
import { useNavigate } from "react-router-dom";
import { getActionSetConfirm, mapPractice, nodeCRUD, savePractice } from "../misc/Functions";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import DialogGetSingleLineOfText from "./DialogGetSingleLineOfText";
import DialogDownloadSendBoth from "./DialogDownloadSendBoth";
import TeamDropdown from "./TeamDropdown";
import { DRUPALENTITYINIT, PRACTICEINIT } from "../misc/Constants";
import SnackbarMessages from "./SnackbarMessages";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

interface TypeFormData {
    team: DrupalEntity,
    date: Date,
    note: string,
}

const commonStyles = {
    fontFamily: '"PT Sans", sans-serif',
    fontWeight: 'bold',

    height: '40px', // Set your desired height here
    '& .MuiInputBase-root': {
        height: '100%',
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: '"PT Sans", sans-serif',
        color: 'grey', // Text color
        '& .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'grey', // Border color
            borderWidth: '1px', // Normal state border thickness
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'grey', // Border color on hover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            // borderColor: 'grey', // Border color when focused
            borderWidth: '2px', // Focused state border thickness
        },
        '& .MuiSvgIcon-root': {
            // color: 'grey', // Icon color
        },
    },
    '& .MuiInputLabel-root': {
        fontFamily: '"PT Sans", sans-serif',
        // fontWeight: 'bold',
        // color: 'grey', // Consistent label color
    },
};

// Show form with practice header edit boxes and options to save, clear etc current practice
export default function PracticeProgramForm() {
    // Get state and dispatcher
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const navigate = useNavigate();
    log.debug('PracticeProgramForm');
    const theme = useTheme();
    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

    // const formDataInit: TypeFormData = {
    //     // initial team is team of current practice or users first team or ''
    //     team: state.curPractice.team.id
    //         ? state.curPractice.team
    //         : (state.allTeams.find(x => x.relationships.uid.data.id === state.user.data.id) || DRUPALENTITYINIT),
    //     date: new Date(state.curPractice.date.setSeconds(0, 0)), // new Date(new Date().setSeconds(0, 0)),
    //     note: state.curPractice.note,
    // }

    // State of data in form
    const [formData, setFormData] = useState<TypeFormData>({ team: DRUPALENTITYINIT, date: new Date(), note: '' })
    const [openDialogGetSingleLineOfText, setOpenDialogGetSingleLineOfText] = useState(false)
    const [openDialogDownloadSendBoth, setOpenDialogDownloadSendBoth] = useState(false)
    const [disabled, setDisabled] = useState(false)

    // On refresh make sure latest state.allTeams is used
    useEffect(() => {
        const formDataInit: TypeFormData = {
            team: state.curPractice.team,
            date: new Date(state.curPractice.date.setSeconds(0, 0)),
            note: state.curPractice.note,
        }
        setFormData(formDataInit)
    }, [state.allTeams])

    const buttons = [
        // Save and optionally send/down practice
        {
            // icon: <SaveIcon />,
            label: t('PracticeProgramForm37'),
            onClick: () => savePracticeProgram(),
            show: true,
        },

        // Clear
        {
            // icon: <ClearAllIcon />,
            label: t('PracticeProgramForm04'),
            onClick: () => {
                dispatch(getActionSetConfirm(t('PracticeProgramForm06'), 'ok', () => {
                    const action: ActionSetPractice = { type: 'setPractice', practice: PRACTICEINIT }
                    dispatch(action)
                    setFormData({
                        team: DRUPALENTITYINIT,
                        date: new Date(),
                        note: ''
                    })
                }));
            },
            show: true,
        },
    ]

    function handleChange(name: string, value: any) {
        // update local data
        setFormData({ ...formData, [name]: value })
        // update global data/state
        const actionSetPractice: ActionSetPractice = {
            type: 'setPractice',
            practice: {
                ...state.curPractice,
                team: name === 'team' ? value : state.curPractice.team,
                date: name === 'date' ? new Date(value) : state.curPractice.date,
                note: name === 'note' ? value : state.curPractice.note,
                dirty: true,
            }
        }
        dispatch(actionSetPractice)
    }

    // if possible, find practice with same team on the same date and time
    function dublicatePractice(): PracticeBetter | undefined {
        return state.allPractices.map(x => mapPractice(state, x)).find(x => x.date.toISOString() === formData.date.toISOString() && x.team.attributes.title === formData.team)
    }

    // Check and confirm before save practice. If user confirms then 'afterSave' is execute if 'afterSave' has a value
    function checkPractice(): boolean {
        let res = false;
        let exerciseDiscontinued = state.curPractice.selectedExercises.find(item => item.exercise.attributes.field_discontinued);//selectedExercises.find(item => item.exercise.attributes.field_discontinued);
        if (!state.curPractice.dirty) {
            dispatch(getActionSetConfirm(t('PracticeProgramForm25')));
        } else if (exerciseDiscontinued) {
            // practice holds discontinued exercises
            dispatch(getActionSetConfirm(`${t('PracticeProgramForm13')} ${exerciseDiscontinued.exercise.attributes.title} ${t('PracticeProgramForm14')}`));
        } else if (state.user.login.current_user.uid === 0) {
            // Anonymous user tries to save a practice
            dispatch(getActionSetConfirm(t('Generel00'), t('AccountCaptcha08'), () => navigate('/signup')));
        } else if (state.curPractice.selectedExercises.length === 0) {//selectedExercises.length === 0) {
            // Save when no exercises - makes no sense!
            dispatch(getActionSetConfirm(t('PracticeProgramForm12')));
        } else if (!formData.team) {
            // Team must be selected
            dispatch(getActionSetConfirm(t('PracticeProgramForm30')));
        } else if (state.curPractice.selectedExercises.find(item => !item.exercise.attributes.drupal_internal__nid)) {//selectedExercises.find(item => !item.exercise.attributes.drupal_internal__nid)) {
            // test user has access to all exercises in practice. If user
            // has retrived an previously saved pratice and that practice holds
            // one or more exercises the user no longer has access to then the
            // user should no be allowed to save a new practice holding exercises
            // he/she no longer has access to
            // alert(t('PracticeProgramForm15'));
            dispatch(getActionSetConfirm(t('PracticeProgramForm15')));
        } else if (state.user.data.attributes.field_user_type === 'free'
            && !dublicatePractice()
            && state.allPractices.filter(x => x.relationships.uid.data.id === state.user.data.id).length >= state.configuration[0].attributes.field_max_practices_per_user) {
            // free user is NOT allowed to save more practices
            dispatch(getActionSetConfirm(t('PracticeProgramForm24'), t('ICBAppBar04'), () => navigate('/setsubscription')));
        } else {
            res = true;
        }
        return res;
    }

    // Check and give option to save/update the modified practice program. 
    // After save/update of practice program the user is given option to see/download/send practice program
    function savePracticeProgram() {
        if (checkPractice()) {
            const dublicate = dublicatePractice()
            dispatch(getActionSetConfirm(`${dublicate ? t('PracticeProgramForm35') : t('PracticeProgramForm33')}`, 'ok', async () => {
                /*
                If we overwrite we have a player list equal to the player list of the practice we overwrite.
                Otherwise, we have a player list based on how the team looks now
                */
                const attendanceNewPractice: Attend[] = formData.team.relationships.field_players.data.map((item: JSONAPITypeId) => ({ playerID: item.id, present: true }))
                const resp = await savePractice(state, dispatch, {
                    date: formData.date,
                    note: formData.note,
                    selectedExercises: state.curPractice.selectedExercises,
                    attendance: dublicate ? dublicate.attendance : attendanceNewPractice,
                    practiceID: dublicate ? dublicate.practiceID : '',
                    team: formData.team,
                    uid: state.user.data.id!,
                })
                if (resp) {
                    // error saving practice
                    log.error(resp);
                    dispatch(getActionSetConfirm(resp));
                } else {
                    setOpenDialogDownloadSendBoth(true)
                    // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                    setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
                }
            }));
        }
    }

    // Close dialog to create new team
    async function onCloseDialogGetSingleLineOfText(ok: boolean, newTeamName: string) {
        if (ok) {
            // User clicked OK
            newTeamName = newTeamName.trim();
            if (!newTeamName) {
                // not valid team name
                dispatch(getActionSetConfirm(t('DialogGetSingleLineOfText04')));
            } else if (state.allTeams.
                find(x => x.attributes.title === newTeamName
                    // only consider teams that below to current user. Think clubadmin - he sees teams of his club members
                    && x.relationships.uid.data.id === state.user.data.id
                )) {
                // team already exists
                dispatch(getActionSetConfirm(`${t('DialogGetSingleLineOfText05')}: ${newTeamName}`));
            } else {
                const resp = await nodeCRUD(state, dispatch, CRUD.Create, {
                    type: 'node--team',
                    attributes: {
                        title: newTeamName
                    }
                })
                if (resp.data) {
                    const action: ActionSetPractice = {
                        type: 'setPractice', practice: {
                            ...state.curPractice,
                            team: resp.data, // better to get from state.allTeams?
                            dirty: true,
                        }
                    }
                    dispatch(action)
                    setOpenDialogGetSingleLineOfText(false)
                    setFormData({ ...formData, team: resp.data })
                    setDisabled(false)
                } else {
                    dispatch(getActionSetConfirm(resp));
                }
            }
        } else {
            // User cancelled the dialog
            setOpenDialogGetSingleLineOfText(false)
            setDisabled(false)
        }
    }

    return (
        <Fragment>
            {/* Dialog to create new team */}
            <DialogGetSingleLineOfText
                open={openDialogGetSingleLineOfText}
                onClose={(ok, text) => onCloseDialogGetSingleLineOfText(ok, text)}
                title={t('PracticeProgramForm28')}
                contentLine1={t('DialogGetSingleLineOfText00')}
                label={t('DialogGetSingleLineOfText02')}
                button={t('DialogGetSingleLineOfText03')}
            />
            {/* {!state.practiceID ? "NEW " : "OLD "} */}
            {/* {state.practiceProgramDirty ? "DIRTY" : "CLEAN"} */}
            <Grid2 container margin={1}>
                <Grid2 xs={12} >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={state.user.locale.substring(0, 2)}>
                        {/* Do not allow an initial undef value as controls would go from uncontrolled to controlled */}

                        <Box sx={{ backgroundColor: '#fff3e0', padding: 0.5, borderRadius: 1, border: '1px solid ' + theme.palette.divider, }}>
                            <Typography textAlign={'center'} sx={{ fontSize: { xs: 20, sm: 23 }, fontWeight: 'bold', color: '#DD6F20' }}>
                                {t('PracticeProgramSelectedExercises00')}
                            </Typography>
                        </Box>

                        <Grid2 container spacing={1} paddingTop={1}>

                            {/* Select team */}
                            <Grid2 xs={6}>
                                <TeamDropdown
                                    handleChange={(value) => {
                                        if (value === t('PracticeProgramForm27')) {
                                            setOpenDialogGetSingleLineOfText(true);
                                            setDisabled(true) // don't allow save/clear when user is creating a new team
                                        } else {
                                            handleChange('team', state.allTeams.find(x => x.id === value)); // we get UUID from TeamDropdown - convert to team
                                        }
                                    }}
                                    selectedTeam={formData.team}
                                    firstElement={t('PracticeProgramForm27')}
                                    filterOnMyTeams={true}
                                />
                            </Grid2>

                            {/* Practice Date */}
                            <Grid2 xs={6}>
                                <DateTimePicker
                                    label={t('PracticeProgramForm17')}
                                    value={dayjs(new Date(formData.date))}
                                    // onChange={(date: Dayjs | null) => handleChange('date', date ? date.unix() * 1000 : 0)}
                                    onChange={(date: Dayjs | null) => handleChange('date', date?.toDate())}
                                    sx={{ ...commonStyles, width: '100%' }}
                                />
                            </Grid2>

                            {/* Practice Note */}
                            <Grid2 xs={12}>
                                <TextField
                                    variant="outlined"
                                    label={t('PracticeProgramForm29')}
                                    size="small"
                                    value={formData.note}
                                    onChange={(event) => handleChange('note', event.target.value)}
                                    fullWidth
                                    sx={{ ...commonStyles }}
                                />
                            </Grid2>
                        </Grid2>
                    </LocalizationProvider>
                </Grid2>

                {/* Show all buttons below input fields */}
                <Grid2 xs={12} display={'flex'} justifyContent={'space-evenly'} paddingTop={1}>
                    {
                        buttons.filter(x => x.show).map((item) => (
                            <Button
                                key={item.label}
                                variant="text"
                                aria-label={`practiceProgramForm_${item.label}`}
                                size="small"
                                // startIcon={item.icon}
                                onClick={item.onClick}
                                disabled={disabled}
                                // style={{ fontSize: '0.7rem' }}
                                sx={{
                                    textTransform: 'none',
                                    fontSize: '0.8rem',
                                    height: '25px',
                                    color: theme.palette.text.primary,
                                    border: '1px solid ' + theme.palette.divider,
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.selected,
                                    }
                                }}
                            >
                                {item.label}
                            </Button>
                        ))
                    }
                </Grid2>
            </Grid2 >

            {/* Floating Save Practice/Finish in portrait mode */}
            {/* {
                state.portrait &&
                <Tooltip title={t('PracticeProgramForm02')} placement="top">
                    <Fab
                        aria-label="save"
                        sx={{
                            position: "fixed",
                            bottom: state.portrait ? sxDistanceFromLowBar : 23,
                            left: state.portrait ? { xs: 13, sm: 21 } : 23,
                            zIndex: 2,
                            backgroundColor: '#00398f',
                            color: 'white',
                            '&:hover': {
                                backgroundColor: '#00398f',
                            }
                        }}
                        onClick={() => {
                            showPracticeProgram();
                        }}
                    >
                        <SaveRoundedIcon />
                    </Fab>
                </Tooltip>
            } */}

            <DialogDownloadSendBoth
                open={openDialogDownloadSendBoth}
                onClose={() => setOpenDialogDownloadSendBoth(false)}
            />

            <SnackbarMessages
                message={t('PracticeProgramForm36')}
                open={openSnackbarMessage}
                icon={<CheckCircleRoundedIcon fontSize="small" sx={{ color: '#2e7d36' }} />}
                color={'#2e7d36'}
            />

        </Fragment >
    );
}
