// tps 27/4/23. Multilanguage for BvR. Use this pattern: https://dev.to/franklin030601/building-a-multi-language-app-with-react-js-2och

import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { formatLanguage, getCookie } from "../misc/Functions";
import getUserLocale from 'get-user-locale';

// Default language from cookie
let lng = formatLanguage(getCookie('icoachbasketball'));
if (lng === '') {
  // Try base language on getUserLocale
  lng = getUserLocale() === 'es-ES' ? 'es' : 'en'
}

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    lng: lng,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${import.meta.env.BASE_URL}i18n/{{lng}}.json`,
    },
  });

export default i18n;
