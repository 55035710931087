// tps 29/2/24 see https://jskim1991.medium.com/swift-two-way-communication-between-ios-webview-and-react-437cf8e82a03

import { useCallback, useContext, useEffect, } from 'react'
import { Context } from '../App';
import { ActionSetFromApp, TypeContext } from '../misc/Types';
import log from "../misc/Logger";

const AppiOSTwoWayCommunication = () => {
    const { dispatch } = useContext(Context) as TypeContext;
    //const [eventFromNative, setEventFromNative] = useState('initial value')
    // log.info(`AppiOSTwoWayCommunication 01`);        

    useEffect(() => {
        // log.info(`AppiOSTwoWayCommunication 02a`);
        window.addEventListener('customevent', customEventHandler)
        return () => {
            // log.info(`AppiOSTwoWayCommunication 02b`);
            window.removeEventListener('customevent', customEventHandler)
        }
    }, [])

    const customEventHandler = useCallback(
        (e: any) => {
            // log.info(`AppiOSTwoWayCommunication 03`);        
            log.info(`AppiOSTwoWayCommunication: "${e.detail.data}"`);
            let action: ActionSetFromApp = { type: 'setFromApp', fromApp: e.detail.data }
            dispatch(action)
        },
        // [setEventFromNative]
        []
    )

    // const onClickHandler = () => {
    //     (window as any).webkit && (window as any).webkit.messageHandlers?.SOME_BRIDGE?.postMessage({
    //         message: "Hello! I'm React.",
    //     })
    // }

    return (
        // <div className="sample-component">
        //     <div className="sample-component__card">
        //         <button
        //             className="sample-component__button"
        //             onClick={onClickHandler}
        //         >
        //             AppiOSTwoWayCommunication
        //         </button>
        //         <div className="sample-component__uuid">{eventFromNative}</div>
        //     </div>
        // </div>
        <></>
    )
}

export default AppiOSTwoWayCommunication
