import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { getImageURL } from '../misc/Functions';
import { useTranslation } from 'react-i18next';

// Define keyframes and add them to the document
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = `
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}`;
document.head.appendChild(styleSheet);

interface PropsIntroApp {
    awaitPaymentProcessing: boolean,
}

// Use the interface with your component
export default function IntroApp(props: PropsIntroApp) {
    const { t } = useTranslation();

    // Define styles directly within the component
    const containerStyle: React.CSSProperties = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '300vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white', // Use the prop with a default value
        animation: 'fadeIn 0s ease-in', //state.showIntro ? 'fadeIn 0s ease-in' : 'fadeOut 0.5s ease-out forwards',
        zIndex: 9999, // Set a high z-index to ensure it's on top
        pointerEvents: 'none', // This will allow clicks to go through the IntroApp component
    };

    // // PREVENT SCROLLING WHEN THE INTROAPP IS BEING SHOWED
    // useEffect(() => {
    //     if (state.showIntro) {
    //         const originalStyle = window.getComputedStyle(document.body).overflow;
    //         document.body.style.overflow = 'hidden'; //PREVENT SCROLLING

    //         return () => {
    //             document.body.style.overflow = originalStyle; // IT RESETS THE SCROLLING BEHAVIOUR
    //         };
    //     }
    // }, [state.showIntro]);


    return (
        <div style={containerStyle}>
            <Backdrop
                sx={{
                    zIndex: 9999,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'transparent',
                }}
                // open={state.showIntro}
                open={true}
            >
                <div style={{ position: 'relative' }}>
                    <CircularProgress
                        style={{ color: 'white' }}
                        size={100}
                    />
                    <img
                        src={getImageURL('logoICBBlue.webp')}
                        alt="App Logo"
                        style={{
                            position: 'absolute',
                            top: '47%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            height: '55px',
                            width: '55px',
                            borderRadius: '50%',
                            animation: 'fadeIn 0.5s ease-in' // Agrega esta línea para aplicar la animación
                        }}
                    />

                    {/* Show info if we wait for payment processing */}
                    {
                        props.awaitPaymentProcessing &&
                        <Typography sx={{ paddingTop: 2, color: 'rgba(0, 0, 0, 0.6)', fontSize: { xs: 13, sm: 16 } }}>
                            {t('IntroApp00')}
                        </Typography>
                    }
                </div>
            </Backdrop>
        </div>
    );
}
