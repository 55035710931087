import { useState, useEffect, useContext } from 'react';
import { Box, Modal, Typography } from '@mui/material';
import PlayAnimationHTMLDoc from './PlayAnimationHTMLDoc';
import { Context } from '../App';
import { TypeContext } from '../misc/Types';

interface Frame {
  id: number;
  players: any[];
  lines: any[];
}

interface PropsDialogPlayAnimation {
  isOpen: boolean;
  onClose: () => void;
  frames: Frame[];
  courtType: 'halfcourt' | 'fullcourt';

}

export default function DialogPlayAnimation({ isOpen, onClose, frames, courtType }: PropsDialogPlayAnimation) {
  const { state } = useContext(Context) as TypeContext;

  const [modalContentUrl, setModalContentUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {

      // WE CALL THE HTML DOC FROM PLAYANIMATIONHTMLDOC.TSX, AND WE PASS THE FRAMES AND COURT TYPE
      const HTMLDoc = PlayAnimationHTMLDoc(frames, courtType)

      const blob = new Blob([HTMLDoc], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      setModalContentUrl(url);
    } else {
      if (modalContentUrl) {
        URL.revokeObjectURL(modalContentUrl);
        setModalContentUrl(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, frames]);

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="animation-modal-title"
      aria-describedby="animation-modal-description"
    >

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          maxWidth: '600px',
          maxHeight: '600px',
          width: '95%',
          height: '95%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 1
        }}
      >
        <Typography sx={{ textAlign: 'center', fontSize: { xs: '12px', sm: '16px' }, fontWeight: 'bold', marginTop: 1 }} id="animation-modal-title">
          {state.curPlay.attributes.title}
        </Typography>
        {modalContentUrl && (
          <iframe
            src={modalContentUrl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title="Animation"
          />
        )}
      </Box>
    </Modal>
  );
};
