import { useContext } from "react"
import { DrupalEntity, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Typography } from "@mui/material";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { CDN } from "../misc/Constants";

interface PropsAuthorImageAndName {
    node: DrupalEntity,
    nameBeforeImage?: boolean,
}

export default function AuthorImageAndName(props: PropsAuthorImageAndName) {
    const { state } = useContext(Context) as TypeContext;

    // use original author if we have one!
    const author = props.node.relationships.field_original_author?.data?.id
        ? state.allUsers.find(x => x.id === props.node.relationships.field_original_author.data.id) || state.user.data
        : state.allUsers.find(x => x.id === props.node.relationships.uid.data.id) || state.user.data;
    const fileIDAuthorImage = author.relationships.user_picture?.data?.id || '';
    const authorImage = fileIDAuthorImage && state.allFiles.find(x => x.id === fileIDAuthorImage)?.attributes.uri.url;

    if (props.nameBeforeImage)
        return (
            <Box display={'flex'}>
                {/* Author */}
                <Typography sx={{ flexGrow: 1, fontSize: { xs: 11, sm: 12 }, marginLeft: 0.5 }} marginTop={'auto'} marginBottom={'auto'}>
                    {`${author.attributes.field_first_name} ${author.attributes.field_last_name}`}
                </Typography>
                {/* Author image */}
                {
                    authorImage
                        ? <img
                            src={`${CDN}${authorImage}.webp`}
                            height="32"
                            width="32"
                            style={{ borderRadius: "50%", height: '32px', width: '32px' }}
                            alt="Imagen del Autor"
                        />
                        : <AccountCircleRoundedIcon />
                }
            </Box>
        )
    else
        return (
            <Box display={'flex'}>
                {/* Author image */}
                {
                    authorImage
                        ? <img
                            src={`${CDN}${authorImage}.webp`}
                            height="32"
                            width="32"
                            style={{ borderRadius: "50%", height: '32px', width: '32px' }}
                            alt="Imagen del Autor"
                        />
                        : <AccountCircleRoundedIcon />
                }
                {/* Author */}
                <Typography sx={{ flexGrow: 1, fontSize: { xs: 11, sm: 12 }, marginLeft: 0.5 }} marginTop={'auto'} marginBottom={'auto'}>
                    {`${author.attributes.field_first_name} ${author.attributes.field_last_name}`}
                </Typography>
            </Box>
        )
}