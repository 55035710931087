import { useContext, useEffect, useState } from "react";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";
import { ExerciseSelected, TypeContext } from "../misc/Types";
import { Context } from "../App";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Switch, Typography, createTheme } from "@mui/material";
import { getFileClubLogo, getImageURL, saveUser, zeroPad } from "../misc/Functions";
import Grid2 from "@mui/material/Unstable_Grid2";
import ExerciseCardPrint from "./ExerciseCardPrint";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import DialogAttendanceListForPractice from "./DialogAttendanceListForPractice";

interface PropsPracticeProgramPrint {
    open: boolean,
    onClose: () => void,
}

export default function DialogPracticeProgramPrint(props: PropsPracticeProgramPrint) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    log.debug('DialogPracticeProgramPrint');

    const [disabled, setDisabled] = useState(false)
    const [field_show_description_in_portra, setField_show_description_in_portra] = useState(false);
    const [openDialogAttendanceListForPractice, setOpenDialogAttendanceListForPractice] = useState(false)

    // field_show_description_in_portra has been flopped. Reenable button
    useEffect(() => {
        const show = state.uiSettings.showDescriptionInPortrait
        setField_show_description_in_portra(show === undefined || show)
        setDisabled(false)
    }, [state.user.data])

    // User has flopped switch to show or not show exercise description
    async function handleChange_field_show_description_in_portra() {
        setDisabled(true)
        const showDescriptionInPortrait = !field_show_description_in_portra
        setField_show_description_in_portra(showDescriptionInPortrait)
        await saveUser(state, dispatch, {
            attributes: {
                field_ui_settings: JSON.stringify({ ...state.uiSettings, showDescriptionInPortrait: showDescriptionInPortrait })
            }
        })
    }

    // Show exercises in practice
    const exercises = () => {
        let endDateTime = new Date(state.curPractice.date)
        return state.curPractice.selectedExercises
            // .filter(x => state.allExercises.find(y => y.attributes.drupal_internal__nid === x.drupal_internal__nid)) // don't show exercises not read yet!
            .map((exerciseSelected: ExerciseSelected, index: number) => {
                let startDateTime = new Date(endDateTime);
                endDateTime.setTime(startDateTime.getTime() + 60 * 1000 * (exerciseSelected.durationMinutes || 0));
                return (
                    <Grid2 xs={state.portrait ? 12 : 6} key={index}>
                        <ExerciseCardPrint
                            /*
                            if the exercise for the exerciseSelected we are going to show has not been set by the recuduer in
                            action setPractice because the exercise was not read at that point in time then we try to see if
                            the exercise has been read now. Code below is only executed if exercise has been read due to the filter
                            live above and what we do below is find the exercise if the exercise was not found by the reducer in action
                            setPracticde
                            */
                            exerciseSelected={{
                                ...exerciseSelected,
                                exercise: exerciseSelected.exercise.id === ''
                                    // set the exercise because the reducer was not able to
                                    ? state.allExercises.find(x => x.attributes.drupal_internal__nid === exerciseSelected.drupal_internal__nid)!
                                    // use the exercise that the reducer set
                                    : exerciseSelected.exercise
                            }}
                            startAndStopTime={`${zeroPad(startDateTime.getHours(), 2)}:${zeroPad(startDateTime.getMinutes(), 2)}-${zeroPad(endDateTime.getHours(), 2)}:${zeroPad(endDateTime.getMinutes(), 2)}`}
                            index={index}
                            displayOnly={false}
                        />
                    </Grid2>
                );
            });
    }

    const theme = createTheme({
        typography: {
            fontSize: 14,
        },
    });

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullScreen={state.portrait}
            PaperProps={{
                style: {
                    borderRadius: state.portrait ? 0 : 16,
                    minWidth: state.portrait ? '' : '750px'
                },
            }}
        >

            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {/* team, date and practice note */}
                <Box>
                    {/* Team */}
                    <Typography fontWeight={'bold'}>
                        {state.curPractice.team?.attributes.title.toUpperCase()}
                    </Typography>
                    {/* Date */}
                    <Typography fontSize={'small'} sx={{ color: theme.palette.text.secondary }}>
                        {new Date(state.curPractice.date).toLocaleDateString() + ' - ' + new Date(state.curPractice.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
                    </Typography>
                    {/* Practice note */}
                    <Typography fontSize={'small'} sx={{ color: theme.palette.text.secondary }}>
                        {state.curPractice.note}
                    </Typography>
                </Box>
                <img
                    style={{ height: 'auto', width: state.portrait ? '40px' : '50px', objectFit: 'scale-down' }}
                    src={getFileClubLogo(state) || getImageURL('logoICBBlue.webp')}
                    alt="Logo del Club"
                />
            </DialogTitle>

            <DialogContent>
                {/* Switch to show or not show description. Switch state is saved in user profile */}
                <Box justifyContent={"space-between"}>
                    <FormControlLabel
                        control={<Switch
                            checked={field_show_description_in_portra}
                            disabled={disabled}
                            size="small"
                            onChange={handleChange_field_show_description_in_portra}
                        />}

                        label={
                            <Typography style={{ fontSize: '10px', color: theme.palette.text.secondary }}>
                                {field_show_description_in_portra ? t('PracticeProgramPrint02') : t('PracticeProgramPrint03')}
                            </Typography>
                        }

                    />
                </Box>

                <Grid2 container spacing={2}> {/* Adding a spacing between grid items */}
                    {state.exercisesRetrivedInitially && exercises()}
                </Grid2>

            </DialogContent>

            <DialogActions sx={{ justifyContent: 'center', alignItems: 'center', position: 'relative', padding: 2, paddingBottom: state.nativeApp ? 4 : 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.onClose()}
                    sx={{ textTransform: 'none', height: '30px' }}
                >
                    {t('PracticeProgramPrint01')}
                </Button>

                <Button
                    variant='outlined'
                    color='success'
                    sx={{ position: 'absolute', right: 15, height: '30px' }}
                    onClick={() => setOpenDialogAttendanceListForPractice(true)}
                >
                    <PlaylistAddCheckCircleRoundedIcon />
                </Button>
                {/* 
                <IconButton sx={{ position: 'absolute', right: 15 }}
                    onClick={() => setOpenDialogAttendanceListForPractice(true)}
                    aria-label="close"
                >
                    <PlaylistAddCheckCircleRoundedIcon />
                </IconButton> */}
            </DialogActions>

            <DialogAttendanceListForPractice
                open={openDialogAttendanceListForPractice}
                onClose={() => setOpenDialogAttendanceListForPractice(false)}
            />

        </Dialog>
    )
}

