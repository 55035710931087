import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "../App";
import { getDD, nodeCRUD } from "../misc/Functions";
import { ActionSetUserData, CRUD, DataGridActions, DataGridUpdate, OperationMode, TypeContext, UserType } from "../misc/Types";
import CRUDList from "./CRUDList";
import { Box, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography, useTheme } from "@mui/material";
import { BACKEND, SHOW_ALL_GROUP } from "../misc/Constants";

export default function Groups() {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const { t } = useTranslation();
    const theme = useTheme();

    const [groupType, setGroupType] = useState(OperationMode.exercise.toString())

    // Only handle groups that come from Drupal and not the Total group that is not in Drupal
    const getRows = state.allGroups.filter(x => x.id !== SHOW_ALL_GROUP && x.attributes.field_group_type === groupType).map((item) => ({
        id: item.id,
        nid: item.attributes.drupal_internal__nid,
        group: item.attributes.title,
    }))

    const [rows, setRows] = useState(getRows)

    // Refresh/F5 - wait for all groups available
    useEffect(() => {
        setRows(getRows)
        dispatch({ type: 'sortGroups' }) // no recursion - look at reducer!
    }, [state.allGroups, groupType])

    const columns = () => [
        { field: 'id', headerName: 'ID', width: 10 },
        { field: 'group', headerName: 'Group', flex: 1, editable: true, },
    ]

    // processRowUpdate is called after rows are moved up/down, values in row updated, row is deleted
    function processRowUpdate() {
        return (dataGridUpdate: DataGridUpdate) =>
            new Promise((resolve, reject) => {
                if (dataGridUpdate.newRow) {
                    // Add or edit group
                    if (state.allGroups.find(x => x.attributes.title === dataGridUpdate.newRow!.group)) {
                        // Group name already exists
                        reject(new Error(t('Groups03')))
                        return
                    } else if (state.allGroups.find(x => x.id === dataGridUpdate.newRow!.id && x.relationships.uid.data.id !== state.user.data.id)) {
                        // Edit group that user did not create
                        reject(new Error(t('AlertMsg37')))
                        return
                    } else {
                        // Create or edit group
                        if (!dataGridUpdate.newRow!.group) {
                            reject(new Error(t('Groups04')))
                            return
                        }
                        let group = state.allGroups.find(x => x.id === dataGridUpdate.newRow!.id)
                        if (group) {
                            // Edit group
                            let groupLocal = JSON.parse(JSON.stringify(group))
                            groupLocal.attributes.title = dataGridUpdate.newRow.group
                            nodeCRUD(state, dispatch, CRUD.Update, groupLocal);
                        } else {
                            // Create group
                            group = {
                                "type": "node--group",
                                "attributes": {
                                    "title": dataGridUpdate.newRow.group,
                                    "field_group_type": groupType,
                                },
                            }
                            nodeCRUD(state, dispatch, CRUD.Create, group);
                        }
                    }
                } else if (dataGridUpdate.delete) {
                    // Delete group
                    const group = state.allGroups.find(x => x.id === dataGridUpdate.id)
                    // Check user created group and not ICB group
                    if (group!.relationships.uid.data.id === state.user.data.id)
                        nodeCRUD(state, dispatch, CRUD.Delete, group!)
                    else {
                        reject(new Error(t('AlertMsg37')))
                        return
                    }
                } else {
                    // Reorder groups - faster way to update user data and deploy new sorting
                    // current sort order in back end
                    const sortOrderCurrent = (state.user.data.attributes.field_groups_sort || '').split(',').filter((x: string) => x !== "")
                    // sort order in current list display - remember only one group type is in this list
                    const sortOrderCurrentGroupType = dataGridUpdate.rows.map((item) => item.nid).toString().split(',')
                    // get total list: current sort order - all items in current list display + current list display
                    const sortOrderNew = sortOrderCurrent.filter((x: string) => !sortOrderCurrentGroupType.includes(x)).concat(sortOrderCurrentGroupType).toString()
                    getDD(state, dispatch, `${BACKEND}/icb-user/set_first_person_user_field_value/field_groups_sort`, 'POST', sortOrderNew)
                        .then(() => {
                            const action: ActionSetUserData = {
                                type: 'setUserData',
                                userData: {
                                    ...state.user.data,
                                    attributes: {
                                        ...state.user.data.attributes,
                                        field_groups_sort: sortOrderNew
                                    }
                                }
                            }
                            // update and use users new sort order
                            dispatch(action)
                            dispatch({ type: 'sortGroups' })
                        })

                }
                resolve(dataGridUpdate.rows)
            })
    }

    const handleChangeGroupType = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupType((event.target as HTMLInputElement).value);
    };


    return (
        <Fragment>
            <Box sx={{ width: '90%', maxWidth: '700px', margin: 'auto', paddingTop: 2 }}>
                <Typography paddingBottom={2} sx={{ fontFamily: 'PT Sans, sans-serif', color: theme.palette.primary.main, fontSize: { xs: '20px', sm: '25px' }, fontWeight: 'bold' }}>
                    {t('Groups00')}
                </Typography>
                <Divider />

                <FormControl sx={{ marginTop: 2 }}>
                    {/* <FormLabel id="demo-row-radio-buttons-group-label">{t('Groups01')}</FormLabel> */}
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={groupType}
                        onChange={handleChangeGroupType}
                    >
                        <FormControlLabel value={OperationMode.exercise} control={<Radio />} label={t('SmallScreenICBAppBar01')} />
                        <FormControlLabel value={OperationMode.play} control={<Radio />} label={t('SmallScreenICBAppBar04')} />
                    </RadioGroup>
                </FormControl>
            </Box>

            <CRUDList
                rows={rows}
                columns={columns()}
                actions={[DataGridActions.save, DataGridActions.cancel, DataGridActions.delete, DataGridActions.edit, DataGridActions.down, DataGridActions.up]}
                processRowUpdate={processRowUpdate}
                labelAddRow={[UserType.clubadmin].includes(state.user.data.attributes.field_user_type) ? t('Groups01') : undefined}
                gridColumnVisibilityModel={{ id: false }}
                // dataColumn="group"
                columnFocusOnNew="group"
                deleteWarning={t('Groups02')}
            />
        </Fragment>
    )
}