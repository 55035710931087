import { useContext, useState } from 'react';
import { Context } from '../App';
import { ActionSetPractice, ActionShowPracticeProgram, CRUD, DrupalEntity, TypeContext, UserType } from '../misc/Types';
import DialogMyContacts from './DialogMyContacts';
import { getActionSetConfirm, nodeCRUD, reportPracticeProgramDownload, reportPracticeProgramEmail } from '../misc/Functions';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Dialog, Grid, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import DialogPracticeProgramPrint from './DialogPracticeProgramPrint';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import DialogAttendanceListForPractice from './DialogAttendanceListForPractice';
import SnackbarMessages from './SnackbarMessages';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import ICBDialogTitle from './ICBDialogTitle';

export interface PropsDialogDownloadSendBoth {
    open: boolean,
    onClose: () => any;
}

// Call DialogICBActions with options to download, send or download and send a practice report PDF
export default function DialogDownloadSendBoth(props: PropsDialogDownloadSendBoth) {
    const { state, dispatch } = useContext(Context) as TypeContext;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();

    const [openDialogMyContacts, setOpenDialogMyContacts] = useState(false)
    const [showPracticeReportAfterSend] = useState(false)
    const [openDialogPracticeProgramPrint, setOpenDialogPracticeProgramPrint] = useState(false) // State to control the modal
    const [openDialogAttendanceListForPractice, setOpenDialogAttendanceListForPractice] = useState(false)
    const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false)

    // Selected buttons are not show if current user is not owner of practice - this test only effects clubadmins
    const myPracticeDisplay = state.user.data.id === state.curPractice.uid ? 'flex' : 'none'

    // Close dialog with email selection and optionally send practice in PDF format
    function onCloseDialogMyContacts(send: boolean) {
        setOpenDialogMyContacts(false)
        if (send)
            reportPracticeProgramEmail(state, dispatch, state.curPractice.practiceID || '', send)
        if (showPracticeReportAfterSend) {
            reportPracticeProgramDownload(state, dispatch, navigate, state.curPractice.practiceID || '') //props.practiceID)
        }
    }

    const downloadButton = (
        <Button
            variant='outlined'
            fullWidth
            startIcon={<DownloadRoundedIcon />}
            style={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => {
                reportPracticeProgramDownload(state, dispatch, navigate, state.curPractice.practiceID || '') //props.practiceID)
                props.onClose()
            }}
        >
            {t("DialogDownloadSendBoth02")}
        </Button>
    );

    const sendButton = (
        <Button
            variant='outlined'
            color='primary'
            startIcon={<SendRoundedIcon />}
            fullWidth
            style={{ textTransform: 'none', fontWeight: 'bold' }}
            onClick={() => {
                if (state.user.data.attributes.field_user_type === UserType.free) {
                    dispatch(getActionSetConfirm(t('AlertMsg39'), t('ICBAppBar04'), () => navigate('/setsubscription')));
                } else {
                    setOpenDialogMyContacts(true)
                }
            }}
        >
            {t("DialogDownloadSendBoth03")}
        </Button>
    );

    const visualizeButton = (
        <Button
            variant='contained'
            fullWidth
            style={{ textTransform: 'none', fontWeight: 'bold' }}
            startIcon={<DescriptionRoundedIcon />}
            onClick={() => { setOpenDialogPracticeProgramPrint(true) }}
        >
            {t("DialogDownloadSendBoth05")}
        </Button>
    );

    const reuseButton = (
        <Button
            variant='outlined'
            color='secondary'
            fullWidth
            startIcon={<PendingActionsIcon />}
            style={{ textTransform: 'none' }}
            onClick={() => {
                // clear praticeID so when user saves the practice a new practice is created
                let actionSetPractice: ActionSetPractice = { type: 'setPractice', practice: { ...state.curPractice, practiceID: '' } }
                dispatch(actionSetPractice);
                // Show practice planner
                let action: ActionShowPracticeProgram = { type: 'showPracticeProgram', show: true };
                dispatch(action);
                navigate('/icbdrills');
                // Make sure dialog is closed if we already are on practice planner
                props.onClose()
            }}
        >
            {t("CRUDList04")}
        </Button>
    );

    const deleteButton = (
        <Button
            variant='text'
            color='secondary'
            fullWidth
            startIcon={<DeleteOutlineRoundedIcon />}
            style={{ textTransform: 'none', color: theme.palette.text.secondary }}
            onClick={() => {
                dispatch(getActionSetConfirm(t('Practices03'), 'OK', () => {
                    const practice: DrupalEntity = {
                        type: 'node--practice',
                        id: state.curPractice.practiceID || '', //props.practiceID,
                    }
                    nodeCRUD(state, dispatch, CRUD.Delete, practice)
                    props.onClose()

                    // Activate Snackbar for 2 seconds. Autohide in parent would be inconsistent when double clicking fast
                    setOpenSnackbarMessage(true); setTimeout(() => { setOpenSnackbarMessage(false); }, 1500)

                }));
            }}
        >
            {t("DialogDownloadSendBoth06")}
        </Button >
    );

    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.onClose}
                PaperProps={{
                    style: {
                        borderRadius: 16,
                        width: '95%',
                        maxWidth: '400px'
                    },
                }}
            >
                <ICBDialogTitle onClose={props.onClose} title={t("DialogDownloadSendBoth00")} />

                {/*Divider*/}
                <div style={{ height: '1px', backgroundColor: theme.palette.divider, width: '90%', margin: '0 auto' }}></div>

                <Box sx={{ padding: 3 }} >
                    <Grid container spacing={1}>
                        {/* Visualize */}
                        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'center' }}>
                            {visualizeButton}
                        </Grid>

                        {/* Attendance */}
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                fullWidth
                                variant='outlined'
                                color='success'
                                onClick={() => setOpenDialogAttendanceListForPractice(true)}
                            >
                                <PlaylistAddCheckCircleRoundedIcon />
                            </Button>
                        </Grid>

                        {/* Download - only in landscape */}
                        {
                            !state.portrait &&
                            <Grid item xs={6} style={{ display: myPracticeDisplay, justifyContent: 'center' }}>
                                {downloadButton}
                            </Grid>
                        }

                        {/* Send */}
                        <Grid item xs={state.portrait ? 12 : 6} style={{ display: 'flex', justifyContent: 'center' }}>
                            {sendButton}
                        </Grid>
                    </Grid>

                    {/* Divider */}
                    <div style={{ display: myPracticeDisplay, height: '1px', backgroundColor: theme.palette.divider, width: '100%', margin: '15px auto' }}></div>

                    {/* Edit */}
                    <Grid item xs={12} style={{ display: myPracticeDisplay, justifyContent: 'center' }}>
                        {reuseButton}
                    </Grid>

                    {/* Divider */}
                    <div style={{ display: myPracticeDisplay, height: '1px', backgroundColor: theme.palette.divider, width: '100%', margin: '15px auto' }}></div>

                    {/* Delete */}
                    <Grid item xs={12} style={{ display: myPracticeDisplay, justifyContent: 'center' }}>
                        {deleteButton}
                    </Grid>
                </Box>

                {/* Select pracitice email recipients */}
                <DialogMyContacts
                    open={openDialogMyContacts}
                    onClose={onCloseDialogMyContacts}
                />

                {/* Show practice - visualize */}
                <DialogPracticeProgramPrint
                    open={openDialogPracticeProgramPrint}
                    onClose={() => setOpenDialogPracticeProgramPrint(false)}
                />

                {/* Attendance list */}
                <DialogAttendanceListForPractice
                    open={openDialogAttendanceListForPractice}
                    onClose={() => setOpenDialogAttendanceListForPractice(false)}
                />
            </Dialog>

            <SnackbarMessages
                message={t('DialogDownloadSendBoth07')}
                open={openSnackbarMessage}
                icon={<DeleteRoundedIcon fontSize="small" sx={{ color: '#ff0000' }} />}
                color={'#ff0000'}
            />
        </>

    );
}