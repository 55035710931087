import { Fragment, useContext, useState } from "react";
import { ActionSetPlaybook, JSONAPITypeId, PropsPlayCard, TypeContext } from "../misc/Types";
import { Box, Typography, IconButton, Stack, Paper, Tooltip, Fab } from "@mui/material";
import { Context } from "../App";
// import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
// import { createZigzagPath, SvgDefs, courtDimensions, Frame } from "./DialogPlayBuilder";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useTheme } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import Grid2 from "@mui/material/Unstable_Grid2";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SnackbarMessages from "./SnackbarMessages";
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import AuthorImageAndName from "./AuthorImageAndName";
import { getActionSetConfirm, getNodeWithUpdatedRelationship, getRelationshipsDataElementFor } from "../misc/Functions";
// import { BasketballHalfCourt } from "./BasketballHalfCourt.svg";
// import { BasketballFullCourt } from "./BasketballFullCourt.svg";
import Favorite from "./Favorite";
import PlayIllustrate from "./PlayIllustrate";
import DialogPlayAnimation from "./DialogPlayAnimation";

// Main component PlayCard
export default function PlayCard({ play, index }: PropsPlayCard) {
  const { state, dispatch } = useContext(Context) as TypeContext;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const playDetails = play.attributes.field_play_details ? JSON.parse(play.attributes.field_play_details) : {};
  const frames = playDetails?.frames || [];
  // const courtType = playDetails?.courtType || 'halfcourt'; // Get courtType from playDetails
  const theme = useTheme();

  const [showPlayOnePhase, setShowPlayOnePhase] = useState(false);
  const [openSnackbarMessage, setOpenSnackbarMessage] = useState(false);
  const [showPlayDescription, setShowPlayDescription] = useState(false);

  const [OpenDialogPlayAnimation, setOpenDialogPlayAnimation] = useState(false);

  // // This component creates a single phase of the play with a specific court type
  // function PlayOnePhase({ frame, courtType }: { frame: Frame; courtType: 'halfcourt' | 'fullcourt' }) {
  //   const { width: courtWidth, height: courtHeight } = courtDimensions[courtType];
  //   // Function to shorten the path at both ends of the line
  //   const shortenPathBothEnds = (
  //     from: { x: number; y: number },
  //     to: { x: number; y: number },
  //     offset: number
  //   ) => {
  //     const deltaX = to.x - from.x;
  //     const deltaY = to.y - from.y;
  //     const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

  //     if (length === 0) return { newFrom: from, newTo: to };

  //     const ratio = offset / length;

  //     // Calculate new start point
  //     const newFrom = {
  //       x: from.x + deltaX * ratio,
  //       y: from.y + deltaY * ratio,
  //     };

  //     // Calculate new end point
  //     const newTo = {
  //       x: to.x - deltaX * ratio,
  //       y: to.y - deltaY * ratio,
  //     };

  //     return { newFrom, newTo };
  //   };

  //   const arrowOffset = 15; // VALUE TO ADJUST THE START OF THE LINE AND THE ARROW DISPLACEMENT

  //   return (
  //     <div style={{ position: 'relative' }}>
  //       {/* Button to add play to the Playbook */}
  //       {
  //         index === undefined &&
  //         <Tooltip title={t('PlayCard04')} enterDelay={1000}>
  //           <Fab
  //             color="primary"
  //             size="small"
  //             sx={{
  //               position: 'absolute',
  //               bottom: '40px',
  //               right: '4px',
  //               opacity: 0.7, // para añadir transparencia
  //               transition: 'opacity 0.3s',
  //               '&:hover': {
  //                 opacity: 0.9,
  //                 backgroundColor: '#00398F',
  //                 color: 'white',
  //               },
  //             }}
  //             onClick={() => {
  //               const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays?.data || [], getRelationshipsDataElementFor(play)]
  //               const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
  //               dispatch(action);
  //               setOpenSnackbarMessage(true);
  //               setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
  //             }}
  //           >
  //             <AddRoundedIcon />
  //           </Fab>
  //         </Tooltip>
  //       }

  //       {/* SVG that shapes the SVG elements */}
  //       <svg
  //         viewBox={`0 0 ${courtWidth} ${courtHeight}`}
  //         style={{ width: '100%', height: '100%', display: 'block', borderRadius: 6 }}
  //         preserveAspectRatio="xMidYMid meet"
  //       >
  //         {courtType === 'halfcourt' ? <BasketballHalfCourt /> : <BasketballFullCourt />}
  //         <SvgDefs />
  //         {frame.lines.map((line) => {
  //           const fromPlayer = frame.players.find(p => p.id === line.fromId);
  //           if (!fromPlayer) return null;

  //           // Original 'from' and 'to' points
  //           const originalFrom = { x: fromPlayer.x, y: fromPlayer.y };
  //           const originalTo = { x: line.toX, y: line.toY };

  //           // Shorten the path at both ends
  //           const { newFrom, newTo } = shortenPathBothEnds(originalFrom, originalTo, arrowOffset);

  //           // Adjust control points based on the shortened points
  //           const controlPoint = {
  //             x: line.controlX,
  //             y: line.controlY,
  //           };

  //           // Generate the 'd' attribute of the path
  //           const pathD = line.type === 'zigzag'
  //             ? createZigzagPath(newFrom, controlPoint, newTo)
  //             : `M ${newFrom.x} ${newFrom.y} Q ${controlPoint.x} ${controlPoint.y} ${newTo.x} ${newTo.y}`;

  //           return (
  //             <path
  //               key={line.id}
  //               d={pathD}
  //               stroke="black"
  //               strokeWidth={2}
  //               fill="none"
  //               strokeDasharray={line.type === 'dashed' ? '5,5' : undefined}
  //               markerEnd={line.type === 'bar' ? 'url(#barEnd)' : 'url(#arrowhead)'}
  //             />
  //           );
  //         })}
  //         {frame.players.map((player) => (
  //           <g key={player.id}>
  //             {player.hasBall && (
  //               <circle cx={player.x} cy={player.y} r={12} stroke="black" strokeWidth={2} fill="none" />
  //             )}
  //             <text
  //               x={player.x}
  //               y={player.y}
  //               textAnchor="middle"
  //               alignmentBaseline="central"
  //               fontSize="23"
  //               fontWeight="bold"
  //               fill="black"
  //             >
  //               {player.number}
  //             </text>
  //           </g>
  //         ))}
  //       </svg>

  //       <Box
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="center"
  //         sx={{
  //           position: "relative",
  //           paddingTop: 0.5,
  //         }}
  //       >
  //         {/* Prev phase button */}
  //         <IconButton
  //           onClick={handlePrev}
  //           disabled={currentFrameIndex === 0}
  //           sx={{
  //             marginRight: 1,
  //             backgroundColor: "rgba(255, 255, 255, 0.7)",
  //             '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
  //           }}
  //         >
  //           <ArrowBackIos style={{ fontSize: 12 }} />
  //         </IconButton>

  //         {/* Phase indicator */}
  //         <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1 }} >
  //           {t('PlayCard00')} {currentFrameIndex + 1} {t('PlayCard01')} {frames.length}
  //         </Typography>

  //         {/* Next phase button */}
  //         <IconButton
  //           onClick={handleNext}
  //           disabled={currentFrameIndex === frames.length - 1}
  //           sx={{
  //             marginLeft: 1,
  //             backgroundColor: "rgba(255, 255, 255, 0.7)",
  //             '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
  //           }}
  //         >
  //           <ArrowForwardIos style={{ fontSize: 12 }} />
  //         </IconButton>
  //       </Box>
  //     </div>
  //   );
  // };

  // // State to track the current frame index
  // const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

  // // Handler to go to the previous frame
  // const handlePrev = () => {
  //   setCurrentFrameIndex((prev) => Math.max(prev - 1, 0));
  // };

  // // Handler to go to the next frame
  // const handleNext = () => {
  //   setCurrentFrameIndex((prev) => Math.min(prev + 1, frames.length - 1));
  // };

  return (
    <Fragment>
      {/* Play card that is NOT selected for a playbook */}
      {index === undefined && (
        <Paper
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '350px',
            margin: 'auto',
            padding: state.portrait ? 1 : 1.5,
            borderRadius: state.portrait ? 2 : 5,
          }}
        >
          <Box display={'flex'} sx={{ flexDirection: "column" }}>
            <AuthorImageAndName node={play} />

            <Box sx={{ position: "relative", flexGrow: 1, marginTop: 1 }}>
              {frames.length > 0 && (
                // Button to add play to playbook
                <Box sx={{ position: "relative", flexGrow: 1 }}>
                  <Tooltip title={t('PlayCard04')} enterDelay={1000}>
                    <Fab
                      color="primary"
                      size="small"
                      sx={{
                        position: 'absolute',
                        bottom: '40px',
                        right: '4px',
                        opacity: 0.7, // para añadir transparencia
                        transition: 'opacity 0.3s',
                        '&:hover': {
                          opacity: 0.9,
                          backgroundColor: '#00398F',
                          color: 'white',
                        },
                      }}
                      onClick={() => {
                        // Check play is not already in playbook
                        if (state.curPlaybook.relationships.field_plays?.data.map((x: JSONAPITypeId) => x.id).includes(play.id)) {
                          dispatch(getActionSetConfirm(t('PlayCard05')));
                          return
                        }
                        const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays?.data || [], getRelationshipsDataElementFor(play)]
                        const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                        dispatch(action);
                        setOpenSnackbarMessage(true);
                        setTimeout(() => { setOpenSnackbarMessage(false); }, 2000)
                      }}
                    >
                      <AddRoundedIcon />
                    </Fab>
                  </Tooltip>
                  {/* <PlayOnePhase frame={frames[currentFrameIndex]} courtType={courtType} /> */}
                  <Box onClick={() => { setOpenDialogPlayAnimation(true) }}>
                    <PlayIllustrate playDetails={playDetails} />
                  </Box>
                </Box>
              )}
            </Box>

            <Box sx={{ display: "flex" }}>

              {/* Edit icon */}
              {play.relationships.uid.data.id === state.user.data.id && (
                <IconButton size="small" onClick={() => {
                  dispatch({ type: 'setPlay', play: play });
                  navigate("/createplay")
                }}>
                  <EditIcon />
                </IconButton>
              )}

              {/* Favorite icon */}
              <Favorite node={play} field='field_favorite_plays' />

              {/* Play name */}
              <Typography
                sx={{
                  fontSize: { xs: 11, sm: 14 },
                  lineHeight: 1,
                  fontWeight: "bold",
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: 1,
                }}
              >
                {play.attributes.title}
              </Typography>
            </Box>

            {!state.portrait && play.attributes.field_play_description && (
              <>
                <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1.4, whiteSpace: 'pre-wrap' }} >
                  {showPlayDescription
                    ? play.attributes.field_play_description
                    : play.attributes.field_play_description.substring(0, 150) + (play.attributes.field_play_description.length > 150 ? '...' : '')
                  }
                </Typography>
                {play.attributes.field_play_description.length > 150 && (
                  <Typography
                    onClick={() => setShowPlayDescription(!showPlayDescription)}
                    sx={{
                      textTransform: 'none',
                      paddingTop: 1,
                      fontSize: '12px',
                      cursor: 'pointer',
                      color: theme.palette.text.secondary,
                      textDecoration: 'underline',
                    }}
                  >
                    {showPlayDescription ? t('ExerciseCard10') : t('ExerciseCard09')}
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Paper >
      )}

      {/* Play card IS selected for a playbook */}
      {index !== undefined && (
        <>
          <Box mt={index === 0 ? 0 : 1}>
            <Grid2
              sx={{
                backgroundColor: "#e1f5fe",
                width: "100%",
                height: '35px',
                borderRadius: 1,
                border: `1px solid ${theme.palette.divider}`,
                padding: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 14, fontWeight: "bold", color: '#00398F' }}>
                  {`${index + 1}. ${play.attributes.title || t("PlayCard02")}`}
                </Typography>
                <IconButton onClick={() => setShowPlayOnePhase(!showPlayOnePhase)} sx={{ marginLeft: 1 }}>
                  {showPlayOnePhase ? <VisibilityOffIcon fontSize="small" sx={{ color: "grey" }} /> : <VisibilityIcon fontSize="small" sx={{ color: "grey" }} />}
                </IconButton>
              </Box>

              <Stack direction="row" spacing={0} alignItems="center">
                {index !== 0 && (
                  <IconButton
                    onClick={() => {
                      const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays.data];
                      [selectedPlays[index - 1], selectedPlays[index]] = [selectedPlays[index], selectedPlays[index - 1]];
                      // const action: ActionSetSelectedPlays = { type: "setSelectedPlays", plays: selectedPlays };
                      const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                      dispatch(action);
                    }}
                  >
                    <ArrowCircleUpRoundedIcon fontSize="small" sx={{ color: "grey" }} />
                  </IconButton>
                )}

                {index !== state.curPlaybook.relationships.field_plays.data.length - 1 && (
                  <IconButton
                    onClick={() => {
                      const selectedPlays: JSONAPITypeId[] = [...state.curPlaybook.relationships.field_plays.data];
                      [selectedPlays[index], selectedPlays[index + 1]] = [selectedPlays[index + 1], selectedPlays[index]];
                      // const action: ActionSetSelectedPlays = { type: "setSelectedPlays", plays: selectedPlays };
                      const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                      dispatch(action);
                    }}
                  >
                    <ArrowCircleDownRoundedIcon fontSize="small" sx={{ color: "grey" }} />
                  </IconButton>
                )}

                <IconButton
                  onClick={() => {
                    const selectedPlays: JSONAPITypeId[] = state.curPlaybook.relationships.field_plays.data.filter((_item: JSONAPITypeId, i: number) => i !== index)
                    const action: ActionSetPlaybook = { type: 'setPlaybook', playbook: getNodeWithUpdatedRelationship(state.curPlaybook, 'field_plays', selectedPlays) }
                    dispatch(action);
                  }}
                >
                  <DeleteOutlineOutlinedIcon fontSize="small" sx={{ color: "grey" }} />
                </IconButton>

              </Stack>
            </Grid2>

            {showPlayOnePhase && (
              <Grid2 container alignItems="flex-start" padding={1} sx={{ flexGrow: 1 }}>
                <Grid2 sx={{ flexGrow: 1 }} onClick={() => {
                  setOpenDialogPlayAnimation(true);
                }}>
                  {/* <PlayOnePhase frame={frames[currentFrameIndex]} courtType={courtType} /> */}
                  <PlayIllustrate playDetails={playDetails} />
                </Grid2>
              </Grid2>
            )}
          </Box>
        </>
      )}

      <SnackbarMessages
        message={t('PlayCard03')}
        open={openSnackbarMessage}
        icon={<PlaylistAddCheckCircleRoundedIcon fontSize="small" sx={{ color: '#00398F' }} />}
        color={'#00398F'}
        landscapeVerticalPosition='bottom'
        landscapeHorizontalPosition='right'
      />

      <DialogPlayAnimation
        isOpen={OpenDialogPlayAnimation}
        onClose={() => { setOpenDialogPlayAnimation(false) }}
        frames={playDetails.frames}
        courtType={playDetails.courtType}
      />
    </Fragment>
  );
}