import { PlayDetails } from "./DialogPlayBuilder";
import { useState } from "react";
import { BasketballHalfCourt } from "./BasketballHalfCourt.svg";
import { BasketballFullCourt } from "./BasketballFullCourt.svg";
import { Box, IconButton, Typography } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import log from "../misc/Logger";
import { useTranslation } from "react-i18next";

export const courtDimensions = {
    halfcourt: { width: 542, height: 455 },
    fullcourt: { width: 910, height: 550 },
};

// Helper functions

// Smooth line for curves
export const approximateBezierLength = (
    from: { x: number; y: number },
    control: { x: number; y: number },
    to: { x: number; y: number }
) => {
    const steps = 10;
    let length = 0;
    let prevPoint = from;

    for (let i = 1; i <= steps; i++) {
        const t = i / steps;
        const xt =
            (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
        const yt =
            (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

        length += Math.hypot(xt - prevPoint.x, yt - prevPoint.y);
        prevPoint = { x: xt, y: yt };
    }

    return length;
};

export const createZigzagPath = (
    from: { x: number; y: number },
    control: { x: number; y: number },
    to: { x: number; y: number },
    amplitude = 3,
    oscillationsPer100px = 12,
    finalSegmentLength = 10
) => {
    // Primero, aproximamos la longitud total de la curva
    const length = approximateBezierLength(from, control, to);
    const totalOscillations = Math.max(
        2,
        Math.round((length / 100) * oscillationsPer100px)
    );

    const steps = 1000;
    const dt = 1 / steps;

    const cumulativeLengths = [0];
    let prevX = from.x;
    let prevY = from.y;
    let totalLength = 0;

    // Calculamos las longitudes acumuladas y los puntos a lo largo de la curva
    for (let i = 1; i <= steps; i++) {
        const t = i * dt;
        const xt =
            (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
        const yt =
            (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

        const dx = xt - prevX;
        const dy = yt - prevY;
        const ds = Math.hypot(dx, dy);

        totalLength += ds;
        cumulativeLengths.push(totalLength);

        prevX = xt;
        prevY = yt;
    }

    // Generamos puntos equiespaciados a lo largo de la curva
    const path = [];
    path.push(`M ${from.x} ${from.y}`);

    const numPoints = steps;
    const desiredLengths = [];
    for (let i = 1; i <= numPoints; i++) {
        desiredLengths.push((i / numPoints) * totalLength);
    }

    for (let i = 0; i < desiredLengths.length; i++) {
        const s = desiredLengths[i];

        // Encontramos el índice donde la longitud acumulada es mayor o igual a s
        let j = 0;
        while (j < cumulativeLengths.length && cumulativeLengths[j] < s) {
            j++;
        }
        if (j === 0) {
            j = 1;
        }

        // Interpolación lineal para encontrar t
        const s0 = cumulativeLengths[j - 1];
        const s1 = cumulativeLengths[j];
        const t0 = (j - 1) * dt;
        const t1 = j * dt;

        const t = t0 + ((s - s0) / (s1 - s0)) * (t1 - t0);

        // Calculamos el punto y la derivada en t
        const xt =
            (1 - t) ** 2 * from.x + 2 * (1 - t) * t * control.x + t ** 2 * to.x;
        const yt =
            (1 - t) ** 2 * from.y + 2 * (1 - t) * t * control.y + t ** 2 * to.y;

        const dxdt =
            2 * (1 - t) * (control.x - from.x) + 2 * t * (to.x - control.x);
        const dydt =
            2 * (1 - t) * (control.y - from.y) + 2 * t * (to.y - control.y);

        const len = Math.hypot(dxdt, dydt);
        const nx = -dydt / len;
        const ny = dxdt / len;

        const frequency = (totalOscillations * Math.PI) / totalLength;
        const offset = amplitude * Math.sin(s * frequency);

        const offsetX = xt + offset * nx;
        const offsetY = yt + offset * ny;

        path.push(`L ${offsetX} ${offsetY}`);
    }

    // Añadimos el segmento final
    const theta = Math.atan2(to.y - control.y, to.x - control.x);
    const finalX = to.x + finalSegmentLength * Math.cos(theta);
    const finalY = to.y + finalSegmentLength * Math.sin(theta);
    path.push(`L ${finalX} ${finalY}`);

    return path.join(' ');
};

// Arrowhead and bar definitions
export const SvgDefs: React.FC = () => (
    <defs>
        <marker
            id="arrowhead"
            markerWidth="10"
            markerHeight="7"
            refX="6"
            refY="3.5"
            orient="auto"
        >
            <polygon points="0 0, 10 3.5, 0 7" fill="black" />
        </marker>
        <marker
            id="barEnd"
            markerWidth="1"
            markerHeight="16"
            refX="0.5"
            refY="8"
            orient="auto"
        >
            <rect x="0" y="0" width="1" height="16" fill="black" />
        </marker>
    </defs>
);

interface PropsPlayIllustrate {
    playDetails: PlayDetails
}

export default function PlayIllustrate(props: PropsPlayIllustrate) {
    log.debug('PlayIllustrate');
    const { t } = useTranslation();

    const [currentFrameIndex, setCurrentFrameIndex] = useState(0);

    const { width: courtWidth, height: courtHeight } = courtDimensions[props.playDetails.courtType];
    // Function to shorten the path at both ends of the line
    const shortenPathBothEnds = (
        from: { x: number; y: number },
        to: { x: number; y: number },
        offset: number
    ) => {
        const deltaX = to.x - from.x;
        const deltaY = to.y - from.y;
        const length = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

        if (length === 0) return { newFrom: from, newTo: to };

        const ratio = offset / length;

        // Calculate new start point
        const newFrom = {
            x: from.x + deltaX * ratio,
            y: from.y + deltaY * ratio,
        };

        // Calculate new end point
        const newTo = {
            x: to.x - deltaX * ratio,
            y: to.y - deltaY * ratio,
        };

        return { newFrom, newTo };
    };

    const arrowOffset = 15; // VALUE TO ADJUST THE START OF THE LINE AND THE ARROW DISPLACEMENT

    // Handler to go to the previous frame
    const handlePrev = () => {
        setCurrentFrameIndex((prev) => Math.max(prev - 1, 0));
    };

    // Handler to go to the next frame
    const handleNext = () => {
        setCurrentFrameIndex((prev) => Math.min(prev + 1, props.playDetails.frames.length - 1));
    };

    return (
        <div style={{ position: 'relative' }}>
            {/* SVG that shapes the SVG elements */}
            <svg
                viewBox={`0 0 ${courtWidth} ${courtHeight}`}
                style={{ width: '100%', height: '100%', display: 'block', borderRadius: 6 }}
                preserveAspectRatio="xMidYMid meet"
            >
                {props.playDetails.courtType === 'halfcourt' ? <BasketballHalfCourt /> : <BasketballFullCourt />}
                <SvgDefs />
                {props.playDetails.frames[currentFrameIndex].lines.map((line) => {
                    const fromPlayer = props.playDetails.frames[currentFrameIndex].players.find(p => p.id === line.fromId);
                    if (!fromPlayer) return null;

                    // Original 'from' and 'to' points
                    const originalFrom = { x: fromPlayer.x, y: fromPlayer.y };
                    const originalTo = { x: line.toX, y: line.toY };

                    // Shorten the path at both ends
                    const { newFrom, newTo } = shortenPathBothEnds(originalFrom, originalTo, arrowOffset);

                    // Adjust control points based on the shortened points
                    const controlPoint = {
                        x: line.controlX,
                        y: line.controlY,
                    };

                    // Generate the 'd' attribute of the path
                    const pathD = line.type === 'zigzag'
                        ? createZigzagPath(newFrom, controlPoint, newTo)
                        : `M ${newFrom.x} ${newFrom.y} Q ${controlPoint.x} ${controlPoint.y} ${newTo.x} ${newTo.y}`;

                    return (
                        <path
                            key={line.id}
                            d={pathD}
                            stroke="black"
                            strokeWidth={2}
                            fill="none"
                            strokeDasharray={line.type === 'dashed' ? '5,5' : undefined}
                            markerEnd={line.type === 'bar' ? 'url(#barEnd)' : 'url(#arrowhead)'}
                        />
                    );
                })}
                {props.playDetails.frames[currentFrameIndex].players.map((player) => (
                    <g key={player.id}>
                        {player.hasBall && (
                            <circle cx={player.x} cy={player.y} r={12} stroke="black" strokeWidth={2} fill="none" />
                        )}
                        <text
                            x={player.x}
                            y={player.y}
                            textAnchor="middle"
                            alignmentBaseline="central"
                            fontSize="23"
                            fontWeight="bold"
                            fill="black"
                        >
                            {player.number}
                        </text>
                    </g>
                ))}
            </svg>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                    position: "relative",
                    paddingTop: 0.5,
                }}
            >
                {/* Prev phase button */}
                <IconButton
                    onClick={handlePrev}
                    disabled={currentFrameIndex === 0}
                    sx={{
                        marginRight: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                    }}
                >
                    <ArrowBackIos style={{ fontSize: 12 }} />
                </IconButton>

                {/* Phase indicator */}
                <Typography sx={{ fontSize: { xs: 11, sm: 13 }, lineHeight: 1 }} >
                    {t('PlayCard00')} {currentFrameIndex + 1} {t('PlayCard01')} {props.playDetails.frames.length}
                </Typography>

                {/* Next phase button */}
                <IconButton
                    onClick={handleNext}
                    disabled={currentFrameIndex === props.playDetails.frames.length - 1}
                    sx={{
                        marginLeft: 1,
                        backgroundColor: "rgba(255, 255, 255, 0.7)",
                        '&:hover': { backgroundColor: "rgba(255, 255, 255, 0.9)" },
                    }}
                >
                    <ArrowForwardIos style={{ fontSize: 12 }} />
                </IconButton>
            </Box>
        </div>
    );
}