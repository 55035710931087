import ReactDOMServer from "react-dom/server";
import { createZigzagPath, Frame, Line, getOffsetPoint } from "./DialogPlayBuilder";
import { BasketballFullCourt } from "./BasketballFullCourt.svg";
import { BasketballHalfCourt } from "./BasketballHalfCourt.svg";

export const generatePathD = (
  line: Line,
  adjustedFrom: { x: number; y: number },
  adjustedTo: { x: number; y: number }
) => {
  if (line.type === 'zigzag') {
    return createZigzagPath(
      adjustedFrom,
      { x: line.controlX, y: line.controlY },
      adjustedTo
    );
  } else {
    return `M ${adjustedFrom.x} ${adjustedFrom.y} Q ${line.controlX} ${line.controlY} ${adjustedTo.x} ${adjustedTo.y}`;
  }
};

// Functions to generate SVG content and animation script
export const generateSVGContent = (framesData: Frame[], courtType: string) => {
  // Use ReactDOMServer to render the SVG court
  const courtSVGContent = ReactDOMServer.renderToStaticMarkup(
    courtType === 'fullcourt' ? <BasketballFullCourt /> : <BasketballHalfCourt />
  );

  const courtWidth = courtType === 'fullcourt' ? 910 : 542; // Ajusta los valores según tus necesidades
  const courtHeight = courtType === 'fullcourt' ? 550 : 455; // Ajusta los valores según tus necesidades

  const allPlayerIds = Array.from(
    new Set(framesData.flatMap((frame) => frame.players.map((player) => player.id)))
  );

  const playersSVG = allPlayerIds
    .map((playerId) => {
      const firstAppearance = framesData.find((frame) =>
        frame.players.some((player) => player.id === playerId)
      );
      const player =
        firstAppearance &&
        firstAppearance.players.find((player) => player.id === playerId);

      if (!player) return '';

      return `
        <g id="player-${player.id}">
          ${player.hasBall
          ? `<circle cx="${player.x}" cy="${player.y}" r="16" stroke="black" stroke-width="2" fill="none" />`
          : ''
        }
          <text x="${player.x}" y="${player.y}" text-anchor="middle" alignment-baseline="middle" font-size="22" font-weight="bold" fill="black">${player.number}</text>
        </g>
        `;
    })
    .join('');

  const allLineIds = Array.from(
    new Set(framesData.flatMap((frame) => frame.lines.map((line) => line.id)))
  );

  const linesSVG = allLineIds
    .map((lineId) => {
      const firstAppearance = framesData.find((frame) =>
        frame.lines.some((line) => line.id === lineId)
      );
      const line =
        firstAppearance &&
        firstAppearance.lines.find((line) => line.id === lineId);

      if (!line) return '';

      const fromPlayer = firstAppearance.players.find(
        (p) => p.id === line.fromId
      );

      if (!fromPlayer) return '';

      const adjustedFrom = getOffsetPoint(
        fromPlayer.x,
        fromPlayer.y,
        line.toX,
        line.toY,
        0
      );
      const adjustedTo = getOffsetPoint(
        line.toX,
        line.toY,
        fromPlayer.x,
        fromPlayer.y,
        0
      );

      const pathD = generatePathD(line, adjustedFrom, adjustedTo);
      const strokeDasharray =
        line.type === 'dashed' ? 'stroke-dasharray="5,5"' : '';
      const markerEnd =
        line.type === 'bar'
          ? 'marker-end="url(#barEnd)"'
          : 'marker-end="url(#arrowhead)"';

      return `
        <path id="line-${line.id}" d="${pathD}" stroke="black" stroke-width="2" fill="none" style="display: none;" ${strokeDasharray} ${markerEnd} />
        `;
    })
    .join('');

  const markerDefinitions = `
      <defs>
        <marker
          id="arrowhead"
          markerWidth="10"
          markerHeight="7"
          refX="6"
          refY="3.5"
          orient="auto"
        >
          <polygon points="0 0, 10 3.5, 0 7" fill="black" />
        </marker>
        <marker
          id="barEnd"
          markerWidth="1"
          markerHeight="16"
          refX="0.5"
          refY="8"
          orient="auto"
        >
          <rect x="0" y="0" width="1" height="16" fill="black" />
        </marker>
      </defs>
    `;

  return `
      <svg id="animation-svg" viewBox="0 0 ${courtWidth} ${courtHeight}" preserveAspectRatio="xMidYMid meet">
        ${courtSVGContent}
        ${markerDefinitions}
        ${playersSVG}
        ${linesSVG}
      </svg>
    `;
};

// Generate the animation script for the html animation
export const generateAnimationScript = (framesData: Frame[]) => {
  // Return a string containing the JavaScript code for the animation
  return `
 const frames = ${JSON.stringify(framesData)};
      const totalFrames = frames.length - 1;
      const durationPerFrame = 2000; // 2 segundos por cuadro
      const totalDuration = totalFrames * durationPerFrame;
      let isPlaying = false;
      let currentTime = 0;
      let lastTimestamp = null;
      const players = {};
      const pathRefs = {};
      const movingBalls = {};

      // Recopilar todos los jugadores a través de los cuadros
      const playerIds = [...new Set(frames.flatMap(frame => frame.players.map(player => player.id)))];
      playerIds.forEach(playerId => {
        players[playerId] = document.getElementById("player-" + playerId);
      });

      // Recopilar todas las líneas a través de los cuadros
      const lineIds = [...new Set(frames.flatMap(frame => frame.lines.map(line => line.id)))];
      lineIds.forEach(lineId => {
        const pathElement = document.getElementById("line-" + lineId);
        if (pathElement) {
          pathRefs[lineId] = pathElement;
          // Inicialmente ocultar todas las líneas
          pathElement.style.display = "none";
        }
      });

      // Controles
      const playPauseButton = document.getElementById("play-pause");
      const progressBar = document.getElementById("progress-bar");
      playPauseButton.addEventListener("click", () => {
        isPlaying = !isPlaying;
        if (isPlaying) {
          playPauseButton.textContent = "⏸";
          lastTimestamp = null;
          requestAnimationFrame(animate);
        } else {
          playPauseButton.textContent = "▶";
        }
      });

      progressBar.addEventListener("input", (event) => {
        const value = parseFloat(event.target.value);
        currentTime = (value / 1000) * totalDuration;
        lastTimestamp = null;
        updateAnimation(currentTime);
      });

      function animate(timestamp) {
        if (!isPlaying) return;

        if (!lastTimestamp) lastTimestamp = timestamp;
        const delta = timestamp - lastTimestamp;
        lastTimestamp = timestamp;

        currentTime += delta;
        if (currentTime > totalDuration) {
          currentTime = totalDuration;
          isPlaying = false;
          playPauseButton.textContent = "▶";
        }

        updateAnimation(currentTime);

        if (isPlaying) {
          requestAnimationFrame(animate);
        }
      }

      function updateAnimation(time) {
        // Actualizar la barra de progreso
        const progressValue = (time / totalDuration) * 1000;
        progressBar.value = progressValue;

        // Determinar el cuadro actual y el valor de t
        let frameIndex = Math.floor(time / durationPerFrame);
        if (frameIndex >= totalFrames) {
          frameIndex = totalFrames - 1;
        }
        const frameTime = time - frameIndex * durationPerFrame;
        const t = Math.min(frameTime / durationPerFrame, 1);

        const currentFrame = frames[frameIndex];
        const nextFrame = frames[frameIndex + 1];

        // Ocultar todas las líneas
        lineIds.forEach(lineId => {
          const pathElement = pathRefs[lineId];
          if (pathElement) {
            pathElement.style.display = "none";
          }
        });

        // Mostrar líneas del cuadro actual
        currentFrame.lines.forEach(line => {
          const pathElement = pathRefs[line.id];
          if (pathElement) {
            pathElement.style.display = "";
            pathElement.style.opacity = "0.3"; // Establecer opacidad
          }
        });

        // Eliminar todas las bolas en movimiento
        Object.values(movingBalls).forEach(ball => {
          ball.remove();
        });
        for (const key in movingBalls) {
          delete movingBalls[key];
        }

        // Actualizar posiciones de los jugadores y estado del balón
        playerIds.forEach(playerId => {
          const playerCurrent = currentFrame.players.find(p => p.id === playerId);
          const playerNext = nextFrame.players.find(p => p.id === playerId);

          if (playerCurrent && playerNext) {
            // Verificar si hay una línea asociada con el movimiento del jugador
            const line = currentFrame.lines.find(line => line.fromId === playerId);

            if (line) {
              const pathElement = pathRefs[line.id];
              if (pathElement) {
                if (line.type === "dashed" && playerCurrent.hasBall) {
                  // Manejar animación de pase
                  // Mover una bola a lo largo de la ruta
                  const totalLength = pathElement.getTotalLength();
                  const point = pathElement.getPointAtLength(t * totalLength);

                  // Si aún no hemos creado una bola para esta línea, crearla
                  if (!movingBalls[line.id]) {
                    const ballElement = document.createElementNS("http://www.w3.org/2000/svg", "circle");
                    ballElement.setAttribute("r", "12");
                    ballElement.setAttribute("stroke", "black");
                    ballElement.setAttribute("stroke-width", "2");
                    ballElement.setAttribute("fill", "none");
                    // Insertar el ballElement en el SVG
                    document.getElementById("animation-svg").appendChild(ballElement);
                    movingBalls[line.id] = ballElement;
                  }

                  // Actualizar la posición de la bola en movimiento
                  movingBalls[line.id].setAttribute("cx", point.x);
                  movingBalls[line.id].setAttribute("cy", point.y);

                  // Mantener la posición del jugador estática
                  updatePlayerPosition(playerId, playerCurrent.x, playerCurrent.y);

                } else {
                  // Mover el jugador a lo largo de la ruta
                  const totalLength = pathElement.getTotalLength();
                  const point = pathElement.getPointAtLength(t * totalLength);
                  updatePlayerPosition(playerId, point.x, point.y);
                }
              }
            } else {
              // Interpolar posición
              const x = playerCurrent.x + (playerNext.x - playerCurrent.x) * t;
              const y = playerCurrent.y + (playerNext.y - playerCurrent.y) * t;
              updatePlayerPosition(playerId, x, y);
            }

            // Manejar estado del balón
            if (t >= 1) {
              updatePlayerHasBall(playerId, playerNext.hasBall);
            } else {
              // Si el jugador está pasando el balón, no debe tener el balón durante el pase
              if (line && line.type === "dashed" && playerCurrent.hasBall) {
                updatePlayerHasBall(playerId, false);
              } else {
                const hasBall = playerCurrent.hasBall;
                updatePlayerHasBall(playerId, hasBall);
              }
            }
          }
        });
      }

      function updatePlayerPosition(playerId, x, y) {
        const playerElement = players[playerId];
        if (playerElement) {
          const textElement = playerElement.querySelector("text");
          textElement.setAttribute("x", x);
          textElement.setAttribute("y", y);

          const circleElement = playerElement.querySelector("circle");
          if (circleElement) {
            circleElement.setAttribute("cx", x);
            circleElement.setAttribute("cy", y);
          }
        }
      }

      function updatePlayerHasBall(playerId, hasBall) {
        const playerElement = players[playerId];
        if (playerElement) {
          const circleElement = playerElement.querySelector("circle");
          if (hasBall && !circleElement) {
            // Añadir círculo
            const newCircle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
            newCircle.setAttribute("cx", playerElement.querySelector("text").getAttribute("x"));
            newCircle.setAttribute("cy", playerElement.querySelector("text").getAttribute("y"));
            newCircle.setAttribute("r", "12");
            newCircle.setAttribute("stroke", "black");
            newCircle.setAttribute("stroke-width", "2");
            newCircle.setAttribute("fill", "none");
            playerElement.insertBefore(newCircle, playerElement.firstChild);
          } else if (!hasBall && circleElement) {
            // Eliminar círculo
            playerElement.removeChild(circleElement);
          }
        }
      }

      // Iniciar la animación
      // requestAnimationFrame(animate);   // That will prevent the autoplay animation
    `;
};

export default function PlayAnimationHTMLDoc(frames: Frame[], courtType: string) {
  return `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>SVG Animation</title>
  <style>
    /* Optional: Add some basic styling */
    #controls {
      text-align: center;
      margin-top: 10px;
    }
    #play-pause {
      padding: 5px 10px;
    }
    #progress-bar {
      width: 90%;
      margin-top: 10px;
    }
  </style>
</head>
<body>
  ${generateSVGContent(frames, courtType)}

  <!-- Controls -->
  <div id="controls">
    <button id="play-pause">▶</button>
    <input type="range" id="progress-bar" min="0" max="1000" value="0">
  </div>

  <script>
    ${generateAnimationScript(frames)}
  </script>
</body>
</html>
`;
}
